import "assets/css/Charger.css";
import "assets/css/Charger.css";
import "reactjs-popup/dist/index.css";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import React, { Component } from "react";

import { API_URL } from "variables/general";
import AddRFID from "./AddRFID";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import PreLoader from "assets/img/preloader.gif";
import UpdateBalance from "./UpdateBalance";
import UpdateRFID from "./UpdateStatus";
import UpdateStatus from "./UpdateStatus";

export default class RFID extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      showPopupUpdate: false,
    };
  }

  componentDidMount() {
    this.handleRFIDList();
    this.handleMembership();
  }

  handleMembership = () => {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_URL + "/my/membership", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultDataMembership: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }

  togglePopupUpdate(val) {
    this.setState({
      update_udid: val.ud_id,
      showPopupUpdate: !this.state.showPopupUpdate,
    });
  }
  removeRFID = (event) => {
    let ud_id = event;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      // redirect: "follow",
      body: JSON.stringify({
        rfid: ud_id,
      }),
    };
    fetch(API_URL + "/updaterfid", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let userDetail = JSON.parse(result);
        console.log(result);

        if (userDetail.status) {
          alert("Entity Removed Successfully");
          window.location.reload();
        } else alert("Error, Please try again later");

        this.setState({});
      })
      .catch((error) => console.log("error", error));
  };

  handleRFIDList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({}),
      redirect: "follow",
    };

    // fetch category details
    fetch(API_URL + "/rfid", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    let apiData = this.state.resultData;
    let membershipData = this.state.resultDataMembership;
    if (membershipData) {
      var membershipItem = JSON.parse(membershipData).data;
      if (membershipItem.dashboard.isActive) {
        if (apiData) {
          var dataItem = JSON.parse(apiData).data;
          return (
            <>
              <div className="content">
                <Button onClick={this.togglePopup.bind(this)}>Add More</Button>
                {this.state.showPopup ? (
                  <AddRFID
                    text="Close Me"
                    closePopup={this.togglePopup.bind(this)}
                  />
                ) : null}
                <Row>
                  <CardBody>
                    <Table responsive style={{ overflow: "hidden" }}>
                      <thead className="text-primary">
                        <tr>
                          <th className="text-center">S.No</th>
                          {/* <th className="text-center">Unique ID</th> */}
                          <th className="text-center">RFID Serial Number</th>
                          <th className="text-center">Status</th>
                        </tr>
                      </thead>

                      {dataItem.RFID.map((val) => (
                        <>
                          <tr className="text-center table">
                            <td className="page-number"></td>
                            {/* <td className="text-center">{val.udId}</td> */}
                            <td className="text-center">{val.rfid}</td>
                            <td className="text-center">
                              {val.status}{" "}
                              <Popup
                                trigger={
                                  <button
                                    style={{
                                      color: "#17a2b8",
                                      backgroundColor: "none",
                                      borderWidth: 0,
                                      float: "right",
                                    }}
                                  >
                                    <i class="fas fa-pen"></i>
                                  </button>
                                }
                                position="right center"
                              >
                                <UpdateStatus udId={val.rfid}> </UpdateStatus>
                              </Popup>
                            </td>
                            <td className="text-center"></td>
                            <td className="text-center">
                              <Button onClick={() => this.removeRFID(val.rfid)}>
                                Remove
                              </Button>
                            </td>
                          </tr>
                        </>
                      ))}
                    </Table>
                  </CardBody>
                </Row>
              </div>
            </>
          );
        } else {
          return (
            <div
              className="wrapper"
              style={{
                backgroundImage: `url(${PreLoader})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                height: "100%",
              }}
            ></div>
          );
        }
      } else {
        return (
          <div className="content">
            <h4>
              Your Dashboard membership has expired, please make payment to
              continue the services.
            </h4>
            <Link to="/admin/membership"> Click here to Continue</Link>
          </div>
        );
      }
    } else {
      return (
        <div
          className="wrapper"
          style={{
            backgroundImage: `url(${PreLoader})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
          }}
        ></div>
      );
    }
  }
}

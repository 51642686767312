import React, { Component } from "react";

import { API_URL } from "variables/general";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/esm/Button";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import CardTitle from "reactstrap/lib/CardTitle";
import Col from "reactstrap/lib/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Input from "reactstrap/lib/Input";
import PreLoader from "assets/img/preloader.gif";
import Row from "reactstrap/lib/Row";
import Select from "react-select";
import Table from "reactstrap/lib/Table";

export default class TicketList extends React.Component {
  constructor() {
    super();
    this.state = {
      pcolor: "",
    };
    // this.getTicketSubject = this.getTicketSubject.bind(this);
    // this.getIssue = this.getIssue.bind(this);
    // this.getStationID = this.getStationID.bind(this);
  }
  componentDidMount() {
    this.handleTicketList();
  }

  handleTicketList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",

      headers: myHeaders,
      body: JSON.stringify({}),
      redirect: "follow",
    };

    // fetch category details
    fetch(API_URL + "/displaytickets", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    let pcolor = "info";
    let apiData = this.state.resultData;

    if (apiData) {
      var dataItem = JSON.parse(apiData).data;
      return (
        <div>
          <Card>
            <CardHeader>Previous Tickets</CardHeader>
            <Table responsive style={{ overflow: "hidden" }}>
              <thead className="text-primary">
                <tr>
                  <th className="text-center">S.No.</th>
                  <th className="text-center">Subject</th>
                  <th className="text-center">Created On</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>

              {dataItem.TicketList.slice(0)
                .reverse()
                .map((val, i) => (
                  <tr>
                    {(() => {
                      if (val.status === "Open") {
                        pcolor = "info";
                      } else pcolor = "success";
                    })()}
                    <td className="text-center">{i + 1}.</td>
                    <td className="text-center">{val.subject}</td>
                    <td className="text-center">{val.createdOn.slice(0,10)} at {val.createdOn.slice(11,21)}</td>
                    <td className="text-center">
                      <Badge pill variant={`${pcolor}`}>
                        {val.status}
                      </Badge>
                    </td>
                  </tr>
                ))}
            </Table>
          </Card>
        </div>
      );
    } else {
      return (
        <div
          className="wrapper"
          style={{
            backgroundImage: `url(${PreLoader})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
          }}
        ></div>
      );
    }
  }
}

// import TableList from "views/Tables.js";

import AddTicket from "components/Ticket/AddTicket";
import Dashboard from "views/Dashboard.js";
import Graph from "variables/charts";
import Icons from "views/Icons";
import LocationGroup from "components/LocationGroup/LocationGroup";
import Membership from "components/Membership/Membership";
import Notifications from "views/Notifications.js";
import Operations from "components/Operations/Operations";
import PayParams from "components/Payment/PayParams";
import RFID from "components/RFID/RFID";
import Stations from "components/Station/Stations";
import UserPage from "views/User.js";

// import ChargerTariff from "components/Operations/ChargerTariff";
/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// import Icons from "views/Icons.js";
// import Maps from "views/Map.js";

// import Typography from "views/Typography.js";
// import UpgradeToPro from "views/Upgrade.js";

// import StationDetails from "components/StationDetails"

var routes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
  // {
  //   path: "/location",
  //   name: "Group Location",
  //   icon: "nc-icon nc-tile-56",
  //   component: LocationGroup,
  //   layout: "/admin",
  // },

  // {
  //   path: "/stations",
  //   name: "Stations",
  //   icon: "nc-icon nc-tile-56",
  //   component: Stations,
  //   layout: "/admin",
  // },
  {
    path: "/operations",
    name: "Operations",
    icon: "nc-icon nc-settings-gear-65",
    component: Operations,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    layout: "/admin",
  },
  {
    path: "/rfid",
    name: "RF-Tag",
    icon: "nc-icon nc-tag-content",
    component: RFID,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-diamond",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },

  {
    path: "/addticket",
    name: "Tickets",
    icon: "nc-icon  nc-send",
    component: AddTicket,
    layout: "/admin",
  },

  {
    path: "/membership",
    name: "Membership",
    icon: "nc-icon nc-diamond",
    component: Membership,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "User Profile",
    icon: "nc-icon nc-single-02",
    component: UserPage,
    layout: "/admin",
  },
  // {
  //   path: "/payparams",
  //   name: "",
  //   icon: "",
  //   component: PayParams,
  //   layout: "/admin",
  // },
  // {
  //   path: "/chargertariff",
  //   name: "Charger Tariff",
  //   icon: "nc-icon nc-single-02",
  //   component: ChargerTariff,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   icon: "nc-icon nc-tile-56",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-caps-small",
  //   component: Typography,
  //   layout: "/admin",
  // },
];
export default routes;

import "assets/css/Charger.css";

import { Button, ThemeProvider } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";

import { API_URL } from "variables/general";
import { Calendar } from "react-date-range";
import Filter from "assets/img/filter.png";
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import PaginationItem from "reactstrap/lib/PaginationItem";
import PreLoader from "assets/img/preloader.gif";
import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import chademo from "assets/img/chademo.png";
import type1 from "assets/img/type1.png";
import type2 from "assets/img/type2.png";

export default class ChargerSessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.handleChargerSessions(1);
  }

  handleChargerSessions = (pageNo) => {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let chargerId = this.props.chargerId;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions;
    if (startDate && endDate) {
      console.log("into the statdate");
      requestOptions = {
        method: "POST",

        headers: myHeaders,
        body: JSON.stringify({
          id: chargerId,
          pageNo: pageNo,
          filterData: {
            gte: startDate,
            lte: endDate,
          },
        }),
        redirect: "follow",
      };
      fetch(API_URL + "/chargersessions", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log("in the then");
          this.setState({
            resultData: result,
            dateSelected: "loaded",
          });
        })
        .catch((error) => console.log("error", error));
    } else
      requestOptions = {
        method: "POST",

        headers: myHeaders,
        body: JSON.stringify({
          id: chargerId,
          pageNo: pageNo,
        }),
        redirect: "follow",
      };

    // fetch category details
    fetch(API_URL + "/chargersessions", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };
  handleStartDate = (date) => {
    console.log("startselected");
    this.setState({
      startDate: date,
      dateSelected: "start",
    });
  };
  handleEndDate = (date) => {
    this.setState({
      endDate: date,
      dateSelected: "end",
    });
  };
  filterSelect = () => {
    console.log("filterselected");
    this.setState({
      dateSelected: "filter",
    });
  };
  render() {
    let sessions = this.state.resultData;

    if (sessions) {
      var dataItem = JSON.parse(sessions).data;
      let active = dataItem.pageNo;
      let items = [];
      for (let number = 1; number <= dataItem.totalPages; number++) {
        items.push(
          <Pagination.Item
            key={number}
            value={number}
            active={number === active}
            onClick={() => this.handleChargerSessions(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
      let showFilter;
      if (this.state.dateSelected === "filter") {
        showFilter = (
          <div style={{ margin: "5px" }}>
            <h6>Start Date</h6>
            <Calendar date={new Date()} onChange={this.handleStartDate} />
          </div>
        );
      } else if (this.state.dateSelected === "start") {
        showFilter = (
          <div style={{ margin: "5px" }}>
            <h6>End Date</h6>
            <Calendar date={new Date()} onChange={this.handleEndDate} />
          </div>
        );
      } else if (this.state.dateSelected === "end") {
        showFilter = <div>{this.handleChargerSessions(1)}LOADING ...</div>;
      } else if (this.state.dateSelected === "loaded") {
        showFilter = null;
      }
      return (
        <>
          <Row>
            <Col md="4" sm="4" xs="4" lg="4" xl="4">
              <button
                onClick={this.filterSelect}
                style={{
                  borderRadius: "5px",
                  borderWidth: 0,
                  backgroundColor: "white",
                  padding: "5px",
                }}
              >
                <img src={Filter} style={{ width: "20px" }} />
              </button>
            </Col>
            <Col>{showFilter}</Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Charger's Sessions</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive style={{ overflow: "hidden" }}>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">Charge Start Mode</th>
                        <th className="text-center">Charge Start Time</th>
                        <th className="text-center">Power Delivered </th>
                        <th className="text-center">Charge Status</th>
                        <th className="text-center">Revenue</th>
                      </tr>
                    </thead>

                    <>
                      {dataItem.chargerSessions.map((val) => (
                        <tr>
                          <td className="text-center">
                            {(() => {
                              if (val.idToken) {
                                if (val.idToken.type === "ISO14443") {
                                  return (
                                    <div>RFID No: {val.idToken.idToken}</div>
                                  );
                                } else if (val.idToken.type === "Central")
                                  return <div>Mobile App</div>;
                              } else return <div>Only Cable Plugged In</div>;
                            })()}
                          </td>
                          <td className="text-center">
                            {(() => {
                              var d = new Date(val.startTime);
                              d.setHours(d.getHours() + 5);
                              d.setMinutes(d.getMinutes() + 30);
                              console.log(d);
                              return (
                                <div>
                                  {d.toTimeString().slice(0, 8)} <br />
                                  {d.toDateString()}
                                </div>
                              );
                            })()}
                          </td>
                          <td className="text-center">
                            {val.powerDelivered} W
                          </td>
                          <td className="text-center">{val.status}</td>
                          <td className="text-center">₹ {val.revenue}</td>
                        </tr>
                      ))}
                    </>
                  </Table>
                </CardBody>
              </Card>
              <div style={{ margin: "0 15px" }}>
                <Pagination
                  style={{
                    display: "flex",
                    width: "100%",

                    flexWrap: "wrap",
                  }}
                  size="sm"
                >
                  {items}
                </Pagination>
              </div>
              <Link
                to={{
                  pathname: "/admin/report",
                  state: { chargerId: this.props.chargerId },
                }}
              >
                <Button> Click for Complete Report</Button>
              </Link>
            </Col>
          </Row>
        </>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

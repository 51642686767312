import "assets/css/Charger.css";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";

import { API_URL } from "variables/general";
import ChargerSessions from "./ChargerSessions";
import Chargers from "./Chargers";
import PreLoader from "assets/img/preloader.gif";
import React from "react";
import ccscombo from "assets/img/ccscombo.png";
import chademo from "assets/img/chademo.png";
import type1 from "assets/img/type1.png";
import type2 from "assets/img/type2.png";

export default class ChargerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: 0,
    };
  }

  componentDidMount() {
    this.handleChargerDetails();
  }

  handleChargerDetails = () => {
    let chargerId = this.props.chargerId;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",

      headers: myHeaders,
      body: JSON.stringify({
        id: chargerId,
      }),
      redirect: "follow",
    };

    // fetch category details
    fetch(API_URL + "/chargerdetails", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  routing = () => {
    this.setState({ flag: 1 });
  };

  render() {
    let apiData = this.state.resultData;
    if (this.state.flag === 1) {
      return (
        <div className="content">
          <Chargers
            stationId={this.props.stationId}
            // meterValue={this.props.meterValue}
          />
        </div>
      );
    } else {
      if (apiData) {
        const dataItem = JSON.parse(apiData).data;
        return (
          <>
            <div className="content">
              <Row>
                <Col>
                  <button
                    onClick={() => this.routing()}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      backgroundColor: "white",
                      borderWidth: "0",
                      color: "#28a7ff",
                      fontWeight: "bolder",
                    }}
                  >
                    {" "}
                    X
                  </button>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card className="icon-big text-center icon-warning">
                    <p className="card-category">Charger ID:</p>
                    <CardTitle tag="p">{dataItem.id}</CardTitle>
                  </Card>
                  {/* <Card className="icon-big text-center icon-warning">
                      <p className="card-category">Status:</p>
                      <CardTitle tag="p"> {dataItem.status}</CardTitle>
                    </Card> */}
                </Col>
                <Col>
                  {/* <Card className="icon-big text-center icon-warning">
                    <p className="card-category">Charger Name: </p>
                    <CardTitle tag="p">{dataItem.chargerName}</CardTitle>{" "}
                  </Card> */}
                  <Card className="icon-big text-center icon-warning">
                    <p className="card-category">Total Capacity: </p>
                    <CardTitle tag="p">
                      {dataItem.totalCapacity} kW
                    </CardTitle>{" "}
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="text-center">
                    <div>
                      <CardTitle tag="p">
                        <span> Connector Types: </span> <br />{" "}
                        {dataItem.connector.map((item) => (
                          <>
                            <Row>
                              <Col>
                                <img
                                  style={{ width: "50px" }}
                                  src={(() => {
                                    switch (item.typeId) {
                                      case 1:
                                        return type1;
                                      case 2:
                                        return type2;
                                      // case 3:
                                      //   return chademo;
                                      // default:
                                      //   return chademo;
                                    }
                                  })()}
                                />{" "}
                                {item.connectorName}
                              </Col>
                            </Row>
                          </>
                        ))}
                      </CardTitle>{" "}
                    </div>
                  </Card>
                </Col>
              </Row>
              <ChargerSessions chargerId={this.props.chargerId} />
            </div>
          </>
        );
      } else {
        return (
          <div
            className="wrapper"
            style={{
              backgroundImage: `url(${PreLoader})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: "100%",
            }}
          ></div>
        );
      }
    }
  }
}

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { API_URL } from "variables/general";
import Button from "react-bootstrap/esm/Button";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import React from "react";

export default class AddRFID extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ud_id: "",
      rfid: "",
      balance: 0,
      status: "Active",
    };
    this.addRFID = this.addRFID.bind(this);
    this.getBalance = this.getBalance.bind(this);
    this.getUdid = this.getUdid.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.getRFID = this.getRFID.bind(this);
    this.getUserId = this.getUserId.bind(this);
  }

  shouldComponentUpdate() {
    // There will be logic here to compare table data to see if its changed..
    return true;
  }

  addRFID = () => {
    let balance = this.state.balance;
    let rfid = this.state.rfid;
    let ud_id = this.state.ud_id;
    let userId = this.state.userId;
    let status = this.state.status;
    if (status != "Active") status = "Inactive";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      // redirect: "follow",
      body: JSON.stringify({
        // user_id: userId,
        // ud_id: ud_id,
        rfid: rfid,
        // balance: balance,
        status: status,
      }),
    };
    fetch(API_URL + "/addrfid", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let userDetail = JSON.parse(result);
        console.log(result);
        if (userDetail.status) {
          alert("Entry Added Successfully");

          window.location.reload();
        } else alert("Error, Please try again later");
        this.setState({});
      })
      .catch((error) => console.log("error", error));
  };
  getUserId = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ userId: val });
  };
  getUdid = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ ud_id: val });
  };
  getRFID = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ rfid: val });
  };
  getBalance = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ balance: val });
  };
  getStatus = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ status: val });
  };

  render() {
    return (
      <>
        <Card>
          <div className="popup">
            <div className="popup_inner">
              <span
                className="nc-icon nc-simple-remove"
                style={{ float: "right", padding: "5px" }}
                onClick={this.props.closePopup}
              ></span>
              <h6 style={{ textAlign: "center", marginTop: "10px" }}>
                Add RF -Tag{" "}
              </h6>
              <Container>
                <FormGroup>
                  {/* <label>User ID</label>
                      <Input
                        placeholder="User ID"
                        type="text"
                        onChange={this.getUserId}
                      />
                      <label>Unique ID</label>
                      <Input
                        placeholder="Unique ID"
                        type="text"
                        onChange={this.getUdid}
                      /> */}
                  <br />
                  <label>RFID Serial Number</label>
                  <Input
                    placeholder="RFID Serial Number"
                    type="text"
                    onChange={this.getRFID}
                  />

                  {/* <label>Default Balance</label>
                      <Input
                        placeholder="Balance"
                        type="number"
                        onChange={this.getBalance}
                      /> */}
                  <br />
                  <label>Set Status of the card as : </label>
                  <br />
                  <select onChange={this.getStatus}>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  <br />
                  <Button
                    className="text-center"
                    onClick={() => {
                      this.addRFID();
                      this.props.closePopup();
                    }}
                  >
                    Add
                  </Button>
                </FormGroup>
              </Container>
            </div>
          </div>
        </Card>
      </>
    );
  }
}

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import {
  Link,
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import React, { Component } from "react";

import { API_URL } from "variables/general";
import Button from "react-bootstrap/esm/Button";
import Two from "assets/img/Two.png";
import { localStorage } from "localStorage";

class Login extends React.Component {
  constructor(props) {
    super();

    this.state = {
      email: "",
      password: "",
      isLoaded: false,
      status: 0,
      token: null,
      loggedIn: false,
      isAdmin: false,
    };

    // method binding
    this.getPassword = this.getPassword.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.loginFunction = this.loginFunction.bind(this);
    // this.handleKeypress = this.handleKeypress.bind(this);
  }
  // componentDidMount() {
  //   if (window.localStorage.getItem("token") != null)
  //     this.setState({
  //       loggedIn: true,
  //     });
  // }

  shouldComponentUpdate() {
    // There will be logic here to compare table data to see if its changed..
    return true;
  }
  getEmail = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ email: val });
  };

  getPassword = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ password: val });
  };
  handleKeypress = (event) => {
    if (event.key === "Enter") {
      this.loginFunction();
    }
  };
  loginFunction = () => {
    let email = this.state.email;
    let password = this.state.password;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      // redirect: "follow",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    };
    fetch(API_URL + "/my/loginwithpassword/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let userDetail = JSON.parse(result);
        if (!userDetail.status) alert("Invalid Credentials");
        // console.log(result);
        window.localStorage.setItem("token", userDetail.data.token);
        this.setState({
          status: userDetail.statusCode,
          loggedIn: true,
        });
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    const style = {
      CenterContent: {
        backgroundImage: `url(${Two})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        padding: "12em 0 17em 0",
      },
      InputGroup: {
        marginLeft: "auto",
        marginRight: "auto",
        width: "20em",
      },
      header: {
        color: "white",
        margin: "30px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    };
    if (this.state.loggedIn) {
      return <Redirect to="/admin/dashboard" />;
    } else
      return (
        <>
          <Card className="card-login card-plain" style={style.CenterContent}>
            <h4 style={style.header}>Login to AEIDTH Dashboard</h4>
            <Form>
              <FormGroup style={style.InputGroup}>
                <Input
                  placeholder="Enter Email Address"
                  type="email"
                  id="exampleEmail"
                  name="email"
                  onChange={this.getEmail}
                  onKeyPress={this.handleKeypress}
                ></Input>
              </FormGroup>
              <FormGroup style={style.InputGroup}>
                <Input
                  placeholder="Enter Password"
                  type="password"
                  onChange={this.getPassword}
                  onKeyPress={this.handleKeypress}
                ></Input>
              </FormGroup>
              <CardFooter className="text-center">
                <Button
                  block
                  className="btn-round"
                  color="info"
                  onClick={() => this.loginFunction()}
                  style={style.InputGroup}
                >
                  Login
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </>
      );
  }
}
export default Login;

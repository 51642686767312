import { Card, Container } from "react-bootstrap";

import { API_URL } from "variables/general";
import Failure from "assets/img/failure.gif";
import PreLoader from "assets/img/preloader.gif";
import React from "react";
import Success from "assets/img/success.gif";

class PayStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.handleChargerDetails();
  }

  handleChargerDetails = () => {
    let orderId = this.props.orderId;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",

      headers: myHeaders,
      body: JSON.stringify({
        orderId: orderId,
      }),
      redirect: "follow",
    };

    // fetch category details
    fetch(API_URL + "/my/gettxnstatus", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    let apiData = this.state.resultData;
    if (apiData) {
      var dataItem = JSON.parse(apiData).data;
      if (dataItem.status === "success") {
        return (
          <div className="content">
            <Container>
            <i class="far fa-check-circle fa-5x"></i>
              <h3>Payment was successful</h3>
              <c>of </c>
              <b> ₹ {dataItem.amount}</b>
              <c> on </c>
              <b>{dataItem.dateTime.slice(0, 10)}</b> <c>at</c>{" "}
              <b>{dataItem.dateTime.slice(11, 19)}</b>
              <c>.</c>
              <br />
              <c>Order Id: </c>
              <b>{dataItem.orderId}</b>
            </Container>
          </div>
        );
      } 
      else if (dataItem.status === "failed") {
        return (
          <div className="content">
            <Container>
              <i class="far fa-times-circle fa-5x"></i>
              <h3>Payment Failed</h3> <c>Please Try again later.</c>
            </Container>
          </div>
        );
      } 
      else if (dataItem.status === "pending") {
        return (
          <div className="content">
            <Container>
            <i class="far fa-clock fa-5x"></i>
              <h3>Payment is Pending from your Bank.</h3>{" "}
              <c>Please be patient.</c>
            </Container>
          </div>
        );
      }
    } else {
      return (
        <div
          className="wrapper"
          style={{
            backgroundImage: `url(${PreLoader})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
          }}
        ></div>
      );
    }
  }
}
export default PayStatus;

// reactstrap components

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { Line, Pie } from "react-chartjs-2";

import { API_URL } from "variables/general";
import { Button } from "react-bootstrap";
import { Calendar } from "react-date-range";
import Chargers from "../Charger/Chargers";
import Filter from "assets/img/filter.png";
import { Link } from "react-router-dom";
import LocationGroup from "components/LocationGroup/LocationGroup";
import { MyDocument } from "components/Document/pdf";
import React from "react";
import { Redirect } from "react-router";
import Stations from "./Stations";

// import StationData from "./StationData";

/*!
 
=========================================================
* Paper Dashboard React - v1.2.0
=========================================================
 
* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
 
* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)
 
* Coded by Creative Tim
 
=========================================================
 
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
*/

export default class StationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      flag: 0,
      station_id: "",
    };
  }

  componentDidMount() {
    this.handleStationDetails();
  }

  handleStationDetails = () => {
    let station_id = this.props.selected_station.stationId;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions;
    if (startDate && endDate) {
      requestOptions = {
        method: "POST",

        headers: myHeaders,
        body: JSON.stringify({
          station_id: station_id,
          filterData: {
            gte: startDate,
            lte: endDate,
          },
        }),
      };

      // fetch category details
      fetch(API_URL + "/stationdetails", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          if (result === 401) {
            window.localStorage.clear();
            window.location.href = "/login";
          } else
            this.setState({
              resultData: result,
              dateSelected: "loaded",
            });
        })
        .catch((error) => console.log("error", error));
    } else {
      requestOptions = {
        method: "POST",

        headers: myHeaders,
        body: JSON.stringify({
          station_id: station_id,
        }),
      };

      // fetch category details
      fetch(API_URL + "/stationdetails", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          if (result === 401) {
            window.localStorage.clear();
            window.location.href = "/login";
          } else
            this.setState({
              resultData: result,
            });
        })
        .catch((error) => console.log("error", error));
    }
  };
  // markOffline = () => {
  //   let station_id = this.props.selected_station.stationId;
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer " + window.localStorage.getItem("token")
  //   );
  //   var requestOptions = {
  //     method: "POST",

  //     headers: myHeaders,
  //     body: JSON.stringify({
  //       station_id: station_id,
  //     }),
  //   };

  //   // fetch details
  //   fetch(API_URL + "/sendboot", requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => {
  //       let success = JSON.parse(result);
  //       console.log(result);
  //       if (success.data.status === "Accepted") {
  //         alert("Station Marked Offline");
  //       } else alert("Error, Please try again later");
  //       this.setState({});
  //     })
  //     .catch((error) => console.log("error", error));
  // };
  // resetStation = () => {
  //   let station_id = this.props.selected_station.stationId;
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer " + window.localStorage.getItem("token")
  //   );
  //   var requestOptions = {
  //     method: "POST",

  //     headers: myHeaders,
  //     body: JSON.stringify({
  //       station_id: station_id,
  //     }),
  //   };

  //   // fetch details
  //   fetch(API_URL + "/sendreset", requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => {
  //       let success = JSON.parse(result);
  //       console.log(result);
  //       if (success.data.status === "Accepted") {
  //         alert("Reset Done");
  //       } else alert("Error, Please try again later");
  //       this.setState({});
  //     })
  //     .catch((error) => console.log("error", error));
  // };
  reload = () => {
    window.location.reload();
  };
  handleStartDate = (date) => {
    // date.preventDefault();
    // let val = date.target.value;
    console.log("startselected");
    this.setState({
      startDate: date,
      dateSelected: "start",
    });
  };
  handleEndDate = (date) => {
    // date.preventDefault();

    // let val = date.target.value;
    this.setState({
      endDate: date,
      dateSelected: "end",
    });
  };
  filterSelect = () => {
    console.log("filterselected");
    this.setState({
      dateSelected: "filter",
    });
  };
  render() {
    let apiData = this.state.resultData;

    if (apiData) {
      const dataItem = JSON.parse(apiData).data;
      if (dataItem) {
        var GraphDataWeek = {};
        var options = {};
        var labelsWeek = [];
        for (var i = 0; i < dataItem.weekly.length; i++) {
          labelsWeek[i] = dataItem.weekly[i].weekValue;
        }
        //labels = { "labels": labels }

        var datasetsWeek = [];
        var yAxisData = [];
        for (var i = 0; i < dataItem.weekly.length; i++) {
          yAxisData[i] = dataItem.weekly[i].weekAvg;
        }
        datasetsWeek[0] = {
          data: yAxisData,
          fill: false,
          borderColor: "#fbc658",
          backgroundColor: "transparent",
          pointBorderColor: "#fbc658",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
        };
        GraphDataWeek.labels = labelsWeek;
        GraphDataWeek.datasets = datasetsWeek;
        options = {
          legend: {
            display: false,
            position: "top",
          },
        };

        //monthly
        var GraphDataMonth = {};
        var labelsMonth = [];
        for (var i = 0; i < dataItem.monthly.length; i++) {
          if (dataItem.monthly[i].monValue === 1) labelsMonth[i] = "Jan";
          if (dataItem.monthly[i].monValue === 2) labelsMonth[i] = "Feb";
          if (dataItem.monthly[i].monValue === 3) labelsMonth[i] = "Mar";
          if (dataItem.monthly[i].monValue === 4) labelsMonth[i] = "Apr";
          if (dataItem.monthly[i].monValue === 5) labelsMonth[i] = "May";
          if (dataItem.monthly[i].monValue === 6) labelsMonth[i] = "Jun";
          if (dataItem.monthly[i].monValue === 7) labelsMonth[i] = "Jul";
          if (dataItem.monthly[i].monValue === 8) labelsMonth[i] = "Aug";
          if (dataItem.monthly[i].monValue === 9) labelsMonth[i] = "Sep";
          if (dataItem.monthly[i].monValue === 10) labelsMonth[i] = "Oct";
          if (dataItem.monthly[i].monValue === 11) labelsMonth[i] = "Nov";
          if (dataItem.monthly[i].monValue === 12) labelsMonth[i] = "Dec";
        }

        var datasetsMonth = [];
        var yAxisData = [];
        for (var i = 0; i < dataItem.monthly.length; i++) {
          yAxisData[i] = dataItem.monthly[i].monAvg;
        }
        datasetsMonth[0] = {
          data: yAxisData,
          fill: false,
          borderColor: "#fbc658",
          backgroundColor: "transparent",
          pointBorderColor: "#fbc658",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
        };
        GraphDataMonth.labels = labelsMonth;
        GraphDataMonth.datasets = datasetsMonth;
        let showFilter;
        if (this.state.dateSelected === "filter") {
          showFilter = (
            <div style={{ margin: "5px" }}>
              <h6>Start Date</h6>
              <Calendar date={new Date()} onChange={this.handleStartDate} />
            </div>
          );
        } else if (this.state.dateSelected === "start") {
          showFilter = (
            <div style={{ margin: "5px" }}>
              <h6>End Date</h6>
              <Calendar date={new Date()} onChange={this.handleEndDate} />
            </div>
          );
        } else if (this.state.dateSelected === "end") {
          showFilter = <div>{this.handleStationDetails()}LOADING ...</div>;
        } else if (this.state.dateSelected === "loaded") {
          showFilter = null;
        }
        return (
          <>
            <div className="content">
              <button
                onClick={this.reload}
                style={{
                  cursor: "pointer",
                  float: "right",
                  backgroundColor: "white",
                  borderWidth: "0",
                  color: "#28a7ff",
                  fontWeight: "bolder",
                  borderRadius: "2px",
                }}
              >
                X
              </button>

              {/* <Button onClick={this.markOffline.bind(this)}>
                      Opt out of Operation
                    </Button> */}
              {/* 
                    <Button onClick={this.resetStation.bind(this)}>
                      Reset Station
                    </Button> */}
              {/* <Button> Docs {<MyDocument dataItem={dataItem}></MyDocument>}</Button */}

              <Row>
                <Col lg="2" md="2"></Col>
                <Col lg="4" md="4" sm="12">
                  <Card>
                    <div className="numbers">
                      <p
                        className="card-category"
                        style={{ textAlign: "center" }}
                      >
                        {dataItem.stationDetails.stationName}
                      </p>
                      <p />
                    </div>
                  </Card>
                </Col>
                <Col lg="4" md="4">
                  <Card>
                    <div className="numbers">
                      <p
                        className="card-category"
                        style={{ textAlign: "center" }}
                      >
                        {dataItem.stationDetails.stationId}
                      </p>
                      <p />
                    </div>
                  </Card>
                </Col>
                <Col lg="2" md="2"></Col>
              </Row>

              <Row>
                <Col lg="4" md="6" sm="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-layout-11 text-warning" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Chargers</p>
                            <CardTitle tag="p">
                              {dataItem.chargerCount}
                            </CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-favourite-28 text-success" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Operative Ports</p>
                            <CardTitle tag="p">
                              {dataItem.operativeCount}
                            </CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-settings text-danger" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Non Operative Ports</p>
                            <CardTitle tag="p">
                              {dataItem.nonOperativeCount}
                            </CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="4" sm="4" xs="4" lg="4" xl="4">
                  <button
                    onClick={this.filterSelect}
                    style={{
                      borderRadius: "5px",
                      borderWidth: 0,
                      backgroundColor: "white",
                      padding: "5px",
                    }}
                  >
                    <img src={Filter} style={{ width: "20px" }} />
                  </button>
                </Col>
                <Col>{showFilter}</Col>
              </Row>
              {/* <StationData station_id={dataItem.station_id} /> */}
              <CardBody>
                <Row>
                  <Col xs="12" md="4">
                    <Card className="icon-big text-center icon-warning">
                      {" "}
                      <p className="card-category">Avg Charging time:</p>
                      <CardTitle tag="p"> {dataItem.avgChgTime} Min</CardTitle>
                    </Card>
                  </Col>
                  <Col xs="12" md="4">
                    <Card className="icon-big text-center icon-warning">
                      <p className="card-category">Unique Transactions: </p>
                      <CardTitle tag="p">{dataItem.sessions}</CardTitle>{" "}
                    </Card>
                  </Col>

                  <Col xs="12" md="4">
                    <Card className="icon-big text-center icon-warning">
                      <p className="card-category">Total Power Delivered:</p>
                      <CardTitle tag="p">
                        {" "}
                        {Math.round((dataItem.totalPower * 1000) / 1000) /
                          1000}{" "}
                        kW
                      </CardTitle>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="4">
                    <Card className="icon-big text-center icon-warning">
                      <p className="card-category">Revenue Generated:</p>
                      <CardTitle tag="p"> ₹ {dataItem.revenue}</CardTitle>
                    </Card>
                  </Col>
                  {/* <Col xs="12" md="4">
                      <Card className="icon-big text-center icon-warning">
                        <p className="card-category">Taxes Incurred:</p>
                        <CardTitle tag="p">₹ {dataItem.tax}</CardTitle>
                      </Card>
                    </Col> */}
                  <Col xs="12" md="4">
                    <Card className="icon-big text-center icon-warning">
                      <p className="card-category">Tariff:</p>
                      <CardTitle tag="p">
                        {" "}
                        ₹ {dataItem.stationDetails.tariff}/kW
                      </CardTitle>
                    </Card>
                  </Col>
                  <Col xs="12" md="4">
                    <Card className="icon-big text-center icon-warning">
                      <p className="card-category">Fixed Charge:</p>
                      <CardTitle tag="p">
                        {" "}
                        ₹ {dataItem.stationDetails.fixedCharge}
                      </CardTitle>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" sm="12" xs="12">
                    <Card className="card-chart">
                      <CardHeader>
                        <CardTitle tag="h5">Average Weekly Revenue</CardTitle>
                        <Line data={GraphDataWeek} options={options} />
                      </CardHeader>
                    </Card>
                  </Col>
                  <Col md="6" sm="12" xs="12">
                    <Card className="card-chart">
                      <CardHeader>
                        <CardTitle tag="h5">Average Monthly Revenue</CardTitle>
                        <Line data={GraphDataMonth} options={options} />
                      </CardHeader>
                    </Card>
                  </Col>
                </Row>
              </CardBody>

              <Card>
                <Chargers
                  stationId={this.props.selected_station.stationId}
                  dataItem={dataItem}
                />
              </Card>
            </div>
          </>
        );
      } else {
        return <div className="content">No data to display</div>;
      }
    } else {
      return (
        <div>
          <h2>Loading...</h2>
        </div>
      );
    }
  }
}

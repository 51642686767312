// reactstrap components

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { Accordion, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { Line, Pie } from "react-chartjs-2";
import { Link, Route, Switch } from "react-router-dom";
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from "variables/charts.js";

import { API_URL } from "variables/general";
import { Calendar } from "react-date-range";
import Databox from "../components/databox/Databox";
import Filter from "assets/img/filter.png";
import Membership from "components/Membership/Membership";
import MembershipAlert from "components/Membership/MembershipAlert";
import MembershipAlertCarrier from "components/Membership/MembershipAlertCarrier";
import PreLoader from "assets/img/preloader.gif";
import React from "react";
import RenderPDF from "components/Document/renderpdf";

// react plugin used to create charts

// core components

/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      No_of_charging_stations: 40,
      No_of_active_chargers: 456,
      Currently_operating: 399,
      Faulted: 57,
      startDate: "",
      endDate: "",
    };
  }

  componentDidMount() {
    this.handleAllStations();
    this.handleMembership();
  }

  handleMembership = () => {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_URL + "/my/membership", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultDataMembership: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  submitDate = () => {
    console.log("loading");
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",

      headers: myHeaders,
      body: JSON.stringify({
        filterData: {
          gte: startDate,
          lte: endDate,
        },
      }),
      redirect: "follow",
    };

    // fetch category details
    fetch(API_URL + "/userallstation", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result === 401) {
          window.localStorage.clear();
          window.location.href = "/login";
        } else
          this.setState({
            resultData: result,
            dateSelected: "loaded",
          });
      })
      .catch((error) => console.log("error", error));
  };

  handleAllStations = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",

      headers: myHeaders,
      body: JSON.stringify({}),
      redirect: "follow",
    };

    // fetch category details
    fetch(API_URL + "/userallstation", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result === 401) {
          window.localStorage.clear();
          window.location.href = "/login";
        } else
          this.setState({
            resultData: result,
          });
      })
      .catch((error) => console.log("error", error));
  };
  handleStartDate = (date) => {
    // date.preventDefault();
    // let val = date.target.value;
    console.log("startselected");
    this.setState({
      startDate: date,
      dateSelected: "start",
    });
  };
  handleEndDate = (date) => {
    // date.preventDefault();

    // let val = date.target.value;
    this.setState({
      endDate: date,
      dateSelected: "end",
    });
  };
  filterSelect = () => {
    console.log("filterselected");
    this.setState({
      dateSelected: "filter",
    });
  };
  render() {
    let apiData = this.state.resultData;
    let membershipData = this.state.resultDataMembership;
    if (membershipData) {
      var membershipItem = JSON.parse(membershipData).data;
      if (membershipItem.dashboard.isActive) {
        if (apiData) {
          var dataItem = JSON.parse(apiData).data;
          if (dataItem.stationCount > 0) {
            let showFilter;
            if (this.state.dateSelected === "filter") {
              showFilter = (
                <div style={{ margin: "5px" }}>
                  <h6>Start Date</h6>
                  <Calendar date={new Date()} onChange={this.handleStartDate} />
                </div>
              );
            } else if (this.state.dateSelected === "start") {
              showFilter = (
                <div style={{ margin: "5px" }}>
                  <h6>End Date</h6>
                  <Calendar date={new Date()} onChange={this.handleEndDate} />
                </div>
              );
            } else if (this.state.dateSelected === "end") {
              showFilter = <div>{this.submitDate()}LOADING ...</div>;
            } else if (this.state.dateSelected === "loaded") {
              showFilter = null;
            }
            return (
              <>
                <div className="content">
                  <Row>
                    <Col md="6" sm="6" xs="6" lg="6" xl="6">
                      <MembershipAlert {...membershipItem} />
                    </Col>
                    <Col md="6" sm="6" xs="6" lg="6" xl="6">
                      <MembershipAlertCarrier {...membershipItem} />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4" sm="4" xs="4" lg="4" xl="4">
                      <OverlayTrigger
                        key="filter-tooltip"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>Filter Data</Tooltip>
                        }
                      >
                        <button
                          onClick={this.filterSelect}
                          style={{
                            borderRadius: "5px",
                            borderWidth: 0,
                            backgroundColor: "white",
                            padding: "5px",
                            margin: "10px",
                          }}
                        >
                          <img src={Filter} style={{ width: "20px" }} />
                        </button>
                      </OverlayTrigger>
                    </Col>
                    <Col>{showFilter}</Col>
                  </Row>
                  <Row>
                    <Col lg="3" md="6" sm="6">
                      <OverlayTrigger
                        key="show-station"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            View all stations
                          </Tooltip>
                        }
                      >
                        <Link
                          to={{
                            pathname: "/admin/location",
                            state: { ownerId: dataItem.userId },
                          }}
                        >
                          <Card className="card-stats">
                            <CardBody>
                              <Row>
                                <Col md="4" xs="5">
                                  <div className="icon-big text-center icon-warning">
                                    <i className="nc-icon nc-world-2 text-info" />
                                  </div>
                                </Col>
                                <Col md="8" xs="7">
                                  <div className="numbers">
                                    <p className="card-category">
                                      Total Stations
                                    </p>
                                    <CardTitle tag="p">
                                      {dataItem.stationCount}
                                    </CardTitle>
                                    <p />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Link>
                      </OverlayTrigger>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="nc-icon nc-layout-11 text-warning" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <p className="card-category">Total Chargers</p>
                                <CardTitle tag="p">
                                  {dataItem.chargerCount}
                                </CardTitle>
                                <p />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="nc-icon nc-favourite-28 text-success" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <p className="card-category">
                                  Ports in Operation
                                </p>
                                <CardTitle tag="p">
                                  {dataItem.operativeCount}
                                </CardTitle>
                                <p />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="nc-icon nc-settings text-danger" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <p className="card-category">
                                  Ports not in operation
                                </p>
                                <CardTitle tag="p">
                                  {dataItem.faultedCount}
                                </CardTitle>
                                <p />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row ls="12" md="12" xl="12">
                    <Col ls="3" md="3">
                      <Card className="icon-big text-center icon-warning">
                        {" "}
                        <p className="card-category">Avg charging time:</p>
                        <CardTitle tag="p">
                          {" "}
                          {dataItem.avgChgTime} Min
                        </CardTitle>
                      </Card>
                    </Col>
                    <Col ls="3" md="3">
                      <Card className="icon-big text-center icon-warning">
                        <p className="card-category">Unique Sessions: </p>
                        <CardTitle tag="p">{dataItem.sessions}</CardTitle>{" "}
                      </Card>
                    </Col>
                    <Col ls="3" md="3">
                      <Card className="icon-big text-center icon-warning">
                        <p className="card-category">Revenue Generated:</p>
                        <CardTitle tag="p">₹ {dataItem.revenue}</CardTitle>
                      </Card>
                    </Col>
                    <Col ls="3" md="3">
                      {/* <Card className="icon-big text-center icon-warning">
                        <p className="card-category">Taxes Incurred:</p>
                        <CardTitle tag="p">₹ {dataItem.tax}</CardTitle>
                      </Card> */}
                      <Card className="icon-big text-center icon-warning">
                        <p className="card-category">Total Power Delivered:</p>
                        <CardTitle tag="p">
                          {" "}
                          {Math.round((dataItem.totalPower * 1000) / 1000) /
                            1000}{" "}
                          kW
                        </CardTitle>
                      </Card>
                    </Col>
                  </Row>
                  <Databox {...dataItem} />
                  {/* <RenderPDF {...dataItem}></RenderPDF> */}
                </div>
              </>
            );
          } else {
            return <div className="content">No station found</div>;
          }
        } else {
          return (
            <div
              className="wrapper"
              style={{
                backgroundImage: `url(${PreLoader})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                height: "100%",
              }}
            ></div>
          );
        }
      } else {
        return (
          <div className="content">
            <h4>
              Your Dashboard membership has expired, please make payment to
              continue the services.
            </h4>
            <Link to="/admin/membership"> Click here to Continue</Link>
          </div>
        );
      }
    } else {
      return (
        <div
          className="wrapper"
          style={{
            backgroundImage: `url(${PreLoader})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
          }}
        ></div>
      );
    }
  }
}

export default Dashboard;

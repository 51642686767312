import { Card, Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import { API_URL } from "variables/general";
import { Link } from "react-router-dom";

class LocationGroup extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.handleGroupList();
  }
  handleGroupList = () => {
    let ownerId = this.props.history.location.state.ownerId;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        ownerId: ownerId,
      }),
    };

    fetch(API_URL + "/my/getgroup", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };
  render() {
    let actualData = this.state.resultData;
    if (actualData) {
      let dataItem = JSON.parse(actualData).data;
      return (
        <div className="content">
          <h3>Location</h3>
          <Row xs={1} md={3} lg={3} xl={3} className="g-4">
            {dataItem.map((i) => (
              <Col>
                <Link
                  to={{
                    pathname: "/admin/stations",
                    state: { groupId: i.groupId },
                  }}
                >
                  <Card>
                    <div
                      className="icon-big text-center icon-warning"
                      style={{ cursor: "pointer" }}
                    >
                      <Card.Body
                        style={{
                          textAlign: "center",
                          fontStyle: "bolder",
                        }}
                      >
                        <h6>{i.locationName}</h6>
                      </Card.Body>
                    </div>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      );
    } else return <div className="content">Loading</div>;
  }
}
export default LocationGroup;

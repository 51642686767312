/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

//dev server
// const API_URL = "https://demo.techxinnovations.in";

//Rajat local
// const API_URL = "http://192.168.1.60:8001";

//my local
// const API_URL = " http://127.0.0.1:8000";

//production
const API_URL = "https://cpo.aeidth.com";

// data for <thead> of table in TableList view
// data for <tbody> of table in TableList view
export { API_URL };

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";

import { API_URL } from "variables/general";
import { Button } from "react-bootstrap";
import { Calendar } from "react-date-range";
import Filter from "assets/img/filter.png";
import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default class ChargerSessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.handleAllChargerSessions();
  }
  handleAllChargerSessions = () => {
    let chargerId = this.props.location.state.chargerId;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions;
    if (startDate && endDate) {
      console.log("into the statdate");
      requestOptions = {
        method: "POST",

        headers: myHeaders,
        body: JSON.stringify({
          id: chargerId,
          filterData: {
            gte: startDate,
            lte: endDate,
          },
        }),
        redirect: "follow",
      };
      fetch(API_URL + "/chargersessions", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log("in the then");
          this.setState({
            resultData: result,
            dateSelected: "loaded",
          });
        })
        .catch((error) => console.log("error", error));
    } else
      var requestOptions = {
        method: "POST",

        headers: myHeaders,
        body: JSON.stringify({
          id: chargerId,
        }),
        redirect: "follow",
      };

    // fetch category details
    fetch(API_URL + "/chargersessions", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          allData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };
  handleStartDate = (date) => {
    console.log("startselected");
    this.setState({
      startDate: date,
      dateSelected: "start",
    });
  };
  handleEndDate = (date) => {
    this.setState({
      endDate: date,
      dateSelected: "end",
    });
  };
  filterSelect = () => {
    console.log("filterselected");
    this.setState({
      dateSelected: "filter",
    });
  };
  render() {
    let allData = this.state.allData;
    if (allData) {
      var allSession = JSON.parse(allData).data;
      let showFilter;
      if (this.state.dateSelected === "filter") {
        showFilter = (
          <div style={{ margin: "5px" }}>
            <h6>Start Date</h6>
            <Calendar date={new Date()} onChange={this.handleStartDate} />
          </div>
        );
      } else if (this.state.dateSelected === "start") {
        showFilter = (
          <div style={{ margin: "5px" }}>
            <h6>End Date</h6>
            <Calendar date={new Date()} onChange={this.handleEndDate} />
          </div>
        );
      } else if (this.state.dateSelected === "end") {
        showFilter = <div>{this.handleAllChargerSessions()}LOADING ...</div>;
      } else if (this.state.dateSelected === "loaded") {
        showFilter = null;
      }
      return (
        <div className="content">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Charger's Sessions</CardTitle>
            </CardHeader>
            <Row>
              <Col md="4" lg="4" xs="4" sm="4">
                <div
                  style={{
                    borderRadius: "5px",
                    borderWidth: 0,
                    backgroundColor: "white",
                    padding: "5px",
                    margin: "0 0 0 100px",
                  }}
                >
                  <ReactHTMLTableToExcel
                    table="table-to-xls"
                    filename="session report"
                    sheet="session report"
                    buttonText="Download Report in XLS format"
                    onClick={this.handleAllChargerSessions}
                  />
                </div>
              </Col>
              <Col md="4" sm="4" xs="4" lg="4" xl="4">
                <button
                  onClick={this.filterSelect}
                  style={{
                    borderRadius: "5px",
                    borderWidth: 0,
                    backgroundColor: "white",
                    padding: "5px",
                    marginTop: "10px",
                  }}
                >
                  <img src={Filter} style={{ width: "20px" }} />
                </button>
              </Col>
            </Row>
            <Row>
              <Col>{showFilter}</Col>
            </Row>

            <table id="table-to-xls">
              <thead className="text-primary">
                <tr>
                  <th className="text-center">S. No.</th>
                  <th className="text-center">Charge Start Mode</th>
                  <th className="text-center">Charge Start Time</th>
                  <th className="text-center">Power Delivered </th>
                  <th className="text-center">Charge Status</th>
                  <th className="text-center">Revenue</th>
                </tr>
              </thead>

              <>
                {allSession.chargerSessions.map((val, i) => (
                  <tr>
                    <td className="text-center">{i + 1}.</td>
                    <td className="text-center">
                      {(() => {
                        if (val.idToken) {
                          if (val.idToken.type === "ISO14443") {
                            return <div>RFID No: {val.idToken.idToken}</div>;
                          } else if (val.idToken.type === "Central")
                            return <div>Mobile App</div>;
                        } else return <div>Only Cable Plugged In</div>;
                      })()}
                    </td>
                    <td className="text-center">
                      {(() => {
                        var d = new Date(val.startTime);
                        d.setHours(d.getHours() + 5);
                        d.setMinutes(d.getMinutes() + 30);
                        console.log(d);
                        return (
                          <div>
                            {d.toTimeString().slice(0, 8)} <br />
                            {d.toDateString()}
                          </div>
                        );
                      })()}
                    </td>
                    <td className="text-center">{val.powerDelivered} W</td>
                    <td className="text-center">{val.status}</td>
                    <td className="text-center">₹ {val.revenue}</td>
                  </tr>
                ))}
              </>
            </table>
          </Card>{" "}
        </div>
      );
    } else {
      return <div className="content">Loading...</div>;
    }
  }
}

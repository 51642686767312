// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import { API_URL } from "variables/general";
import PreLoader from "assets/img/preloader.gif";
import React from "react";
import profileImage from "assets/img/profileImage.jpg";

class User extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.handleUserDetails();
  }
  handleUserDetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",

      headers: myHeaders,
      body: JSON.stringify({}),
      redirect: "follow",
    };

    // fetch category details
    fetch(API_URL + "/my/userdetails", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    let apiData = this.state.resultData;
    if (apiData) {
      const dataItem = JSON.parse(apiData).data;
      return (
        <>
          <div className="content">
            <Row>
              <Col md="4">
                <Card className="card-user">
                  <div className="image"></div>
                  <CardBody>
                    <div className="author">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="avatar border-gray"
                          src={profileImage}
                        />
                        <h5 className="title">
                          {dataItem.userDetail.firstName}{" "}
                          {dataItem.userDetail.lastName}
                        </h5>
                      </a>
                      <p className="description">
                        {dataItem.userDetail.username}
                      </p>
                      <p className="description">
                        {dataItem.userDetail.phoneNumber}
                      </p>
                      <p className="description">{dataItem.userDetail.email}</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="8">
                <Card className="card-user">
                  <CardHeader>
                    <CardTitle tag="h5">
                      {/* Edit */}
                      Profile
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col className="pr-1" md="5">
                          <FormGroup>
                            <label>Company</label>
                            <Input
                              defaultValue={dataItem.userDetail.company}
                              disabled
                              placeholder="Company"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="px-1" md="3">
                          <FormGroup>
                            <label>Username</label>
                            <Input
                              disabled
                              defaultValue={dataItem.userDetail.username}
                              placeholder="Username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pl-1" md="4">
                          <FormGroup>
                            <label htmlFor="exampleInputEmail1">
                              Email address
                            </label>
                            <Input
                              placeholder="Email"
                              disabled
                              defaultValue={dataItem.userDetail.email}
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Full Name</label>
                            <Input
                              defaultValue={
                                dataItem.userDetail.firstName +
                                " " +
                                dataItem.userDetail.lastName
                              }
                              placeholder="Company"
                              type="text"
                              disabled
                            ></Input>
                          </FormGroup>
                        </Col>
                        <Col className="pl-1" md="6">
                          <FormGroup>
                            <label>Contact</label>
                            <Input
                              defaultValue={dataItem.userDetail.phoneNumber}
                              placeholder="Contact Number"
                              type="number"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Address</label>
                            <Input
                              defaultValue={
                                dataItem.userDetail.addresses[0].address
                              }
                              placeholder="Home Address"
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>City</label>
                            <Input
                              defaultValue={
                                dataItem.userDetail.addresses[0].city
                              }
                              placeholder="City"
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                        disabled
                          defaultValue={dataItem.userDetail.country}
                          placeholder="Country"
                          type="text"
                        />
                      </FormGroup>
                    </Col> */}
                        <Col className="pl-1" md="4">
                          <FormGroup>
                            <label>Post Code</label>
                            <Input
                              defaultValue={
                                dataItem.userDetail.addresses[0].postalCode
                              }
                              placeholder="ZIP Code"
                              type="number"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>About Me</label>
                          <Input
                            type="textarea"
                            defaultValue="Oh so, your weak rhyme You doubt I'll bother, reading into it"
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                      <Row>
                        <div className="update ml-auto mr-auto">
                          {/* <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          Update Profile
                        </Button> */}
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    } else {
      return (
        <div
          className="wrapper"
          style={{
            backgroundImage: `url(${PreLoader})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
          }}
        ></div>
      );
    }
  }
}

export default User;

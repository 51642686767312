import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import Logo from "assets/img/Final.png";
import React from "react";

const styles = StyleSheet.create({
  title: {
    margin: 20,
    fontSize: 25,
    textAlign: "center",
    backgroundColor: "#e4e4e4",
    textTransform: "uppercase",
    fontFamily: "Oswald",
  },
  logo: {
    width: "144px",
  },
  body: {
    flexGrow: 1,
    flexWrap: "wrap",
    padding: "10px",
  },
  h1: {
    fontSize: "36px",
    margin: "20px 0",
  },
  h2: {
    fontSize: "30px",
    margin: "12px 0",
  },
  h3: {
    fontSize: "24px",
    margin: "5px 0",
  },
  row: {
    flexGrow: 1,
    flexDirection: "row",
  },
  col: {
    flexGrow: 1,
    flexDirection: "column",
  },
  block: {
    flexGrow: 1,
  },
  text: {
    width: "60%",
    margin: 10,
    fontFamily: "Oswald",
    textAlign: "justify",
  },
  margin20: {
    margin: "20px 0",
  },
  margin10: {
    margin: "10px 0",
  },
});

const MyDoc = (dataItem) => (
  <Document>
    <Page size="A4">
      <View style={styles.body}>
        <Image style={styles.logo} src={Logo}></Image>
        <Text style={styles.h1}>Report for Charging Stations</Text>
        <Text> Total Number of Stations : {dataItem.stationCount}</Text>

        <Text> Average Charging Time : {dataItem.avgChgTime} min</Text>

        <Text> Stations in Operation : {dataItem.operativeCount}</Text>

        <Text> Stations not in Operation : {dataItem.faultedCount}</Text>

        <Text> Total number of Chargers: {dataItem.chargerCount}</Text>

        <Text> Number of Unique Sessions : {dataItem.sessions}</Text>

        <Text> Total Revenue Generated : Rs. {dataItem.revenue}</Text>

        <Text> Overall Tax Incurred : Rs. {dataItem.tax}</Text>

        <Text> Total Power Delivered : {dataItem.totalPower} kW</Text>

        <Text style={styles.h2}> Station Wise Details:</Text>

        {dataItem.stationDetails.map((val) => (
          <>
            <View style={styles.margin20}>
              <Text> Station ID : {val.stationId}</Text>
              <Text> Station Name : {val.stationName}</Text>
              <Text> Fixed Charges : Rs. {val.fixedCharge}</Text>
              <Text> Tariff Charges : Rs. {val.tariff}</Text>
              <Text> Address : {val.location.address}</Text>
              <Text style={styles.h3}> Chargers in this Station:</Text>
              {val.evse.map((val) => (
                <>
                  <View style={styles.margin10}>
                    <Text> Charger ID : {val.id}</Text>
                    <Text> Charger Type : {val.details.type}</Text>
                    <Text> Charger Name : {val.details.chargerName}</Text>
                    <Text> Status : {val.details.status}</Text>
                    <Text> Warranty Details : {val.details.warranty}</Text>
                    
                  </View>
                </>
              ))}
            </View>
            <Text>
                      {"<------------------------------------------------->"}
                    </Text>
          </>
        ))}
      </View>
    </Page>
  </Document>
);

export default MyDoc;

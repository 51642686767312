import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import React, { Component } from "react";

import { API_URL } from "variables/general";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import PreLoader from "assets/img/preloader.gif";

export default class Operations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validatedFC: false,
      validatedTariff: false,
    };
    this.getTariff = this.getTariff.bind(this);
    this.getFixedCharge = this.getFixedCharge.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.getStationID = this.getStationID.bind(this);
  }
  componentDidMount() {
    this.handleStationList();
    this.handleMembership();
  }

  handleMembership = () => {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_URL + "/my/membership", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultDataMembership: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  handleStationList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",

      headers: myHeaders,
     
      redirect: "follow",
    };

    // fetch category details
    fetch(API_URL + "/stationlist", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  updateTariff = () => {
    let tariff = this.state.tariff;
    let StationID = this.state.StationID;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      // redirect: "follow",
      body: JSON.stringify({
        tariff: parseFloat(tariff),
        stationId: StationID,
      }),
    };
    fetch(API_URL + "/edittariff", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let returnData = JSON.parse(result);
        if (returnData.status) {
          alert(
            "Tariff Updated Successfully as ₹" +
              returnData.data.tariff +
              " for Station " +
              returnData.data.stationId +
              " " +
              returnData.data.stationName
          );
          window.location.reload();
        } else alert("Error, Please try again later");
        this.setState({});
      })
      .catch((error) => console.log("error", error));
  };
  updateFixedCharge = () => {
    let FixedCharge = this.state.FixedCharge;
    let StationID = this.state.StationID;
    // if (StationID==null){
    //   alert("Please select Station")
    //   window.location.reload();
    // }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      // redirect: "follow",
      body: JSON.stringify({
        FixedCharge: parseFloat(FixedCharge),
        stationId: StationID,
      }),
    };
    fetch(API_URL + "/editfixedcharge", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let returnData = JSON.parse(result);
        if (returnData.status) {
          alert(
            "Fixed Charge Updated Successfully as ₹" +
              returnData.data.fixedCharge +
              " for Station " +
              returnData.data.stationId +
              " " +
              returnData.data.stationName
          );
          window.location.reload();
        } else alert("Error, Please try again later");
        this.setState({});
      })
      .catch((error) => console.log("error", error));
  };
  handleSubmitTariff = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validatedTariff: true });
    if (form.checkValidity() === true) {
      this.updateTariff();
    }
  };
  handleSubmitFixedCharge = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validatedFC: true });
    if (form.checkValidity() === true) {
      this.updateFixedCharge();
    }
  };

  getStationID = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ StationID: val });
  };
  getTariff = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ tariff: val });
  };
  getFixedCharge = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ FixedCharge: val });
  };

  render() {
    // console.log('id', this.props.location.query);
    let membershipData = this.state.resultDataMembership;
    let apiData = this.state.resultData;
    if (membershipData) {
      var membershipItem = JSON.parse(membershipData).data;
      if (membershipItem.dashboard.isActive) {
        if (apiData) {
          var dataItem = JSON.parse(apiData).data;
          return (
            <>
              <div className="content">
                <Row>
                  <Col xs="12" md="6" sm="12">
                    <h4>Set Tariff per kW</h4>
                    <Container>
                      <Form
                        noValidate
                        validated={this.state.validatedTariff}
                        onSubmit={this.handleSubmitTariff}
                      >
                        <Form.Group controlId="validationCustomUsername">
                          <Form.Label>Select Station</Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              as="select"
                              onChange={this.getStationID}
                              required
                              aria-describedby="inputGroupPrepend"
                            >
                              <option value="" selected disabled hidden>
                                Select Station
                              </option>
                              {dataItem.stationList.map((val) => (
                                <option value={val.stationId}>
                                  {val.stationId} &ensp;{val.stationName} (₹
                                  {val.tariff})
                                </option>
                              ))}
                            </Form.Control>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="validationCustomUsername">
                          <Form.Label>Enter Tariff (Ex.₹ 15.50)</Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              type="number"
                              placeholder="Enter Tariff"
                              aria-describedby="inputGroupPrepend"
                              required
                              step="0.01"
                              onChange={this.getTariff}
                            />
                          </InputGroup>
                        </Form.Group>

                        <Button type="submit">Update Tariff</Button>
                      </Form>
                    </Container>
                  </Col>
                  <Col xs="12" md="6" sm="12">
                    <h4>Set Fixed Charges for Station</h4>
                    <Container>
                      <Form
                        noValidate
                        validated={this.state.validatedFC}
                        onSubmit={this.handleSubmitFixedCharge}
                      >
                        <Form.Group controlId="validationCustomUsername">
                          <Form.Label>Select Station</Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              as="select"
                              onChange={this.getStationID}
                              required
                              aria-describedby="inputGroupPrepend"
                            >
                              <option value="" selected disabled hidden>
                                Select Station
                              </option>
                              {dataItem.stationList.map((val) => (
                                <option value={val.stationId}>
                                  {val.stationId} &ensp;{val.stationName} (₹
                                  {val.fixedCharge})
                                </option>
                              ))}
                            </Form.Control>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="validationCustomUsername">
                          <Form.Label>
                            Enter Fixed Charge (Ex.₹ 5.50)
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              type="number"
                              placeholder="Enter Fixed Charge"
                              aria-describedby="inputGroupPrepend"
                              required
                              step="0.01"
                              onChange={this.getFixedCharge}
                            />
                          </InputGroup>
                        </Form.Group>

                        <Button type="submit">Update Fixed Charge</Button>
                      </Form>
                    </Container>
                  </Col>
                </Row>
              </div>
            </>
          );
        } else {
          return (
            <div
              className="wrapper"
              style={{
                backgroundImage: `url(${PreLoader})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                height: "100%",
              }}
            ></div>
          );
        }
      } else {
        return (
          <div className="content">
            <h4>
              Your Dashboard membership has expired, please make payment to
              continue the services.
            </h4>
            <Link to="/admin/membership"> Click here to Continue</Link>
          </div>
        );
      }
    } else {
      return (
        <div
          className="wrapper"
          style={{
            backgroundImage: `url(${PreLoader})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
          }}
        ></div>
      );
    }
  }
}

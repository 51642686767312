import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { API_URL } from "variables/general";
import Button from "react-bootstrap/esm/Button";
import { Form } from "react-bootstrap";
import React from "react";

export default class UpdateStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateStatus = () => {
    let status = this.state.status;

    let ud_id = this.props.udId;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      // redirect: "follow",
      body: JSON.stringify({
        rfid: ud_id,
        status: status,
      }),
    };
    fetch(API_URL + "/updaterfid", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let userDetail = JSON.parse(result);
        console.log(result);
        if (userDetail.status) {
          alert("Status Updated Successfully");
          window.location.reload();
        } else alert("Error, Please try again later");
        this.setState({});
      })
      .catch((error) => console.log("error", error));
  };
  getStatus = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ status: val });
  };
  render() {
    return (
      <>
        <Form>
          <Form.Label>Set Status</Form.Label>
          <select onChange={this.getStatus.bind(this)}>
            <option value="" selected disabled hidden>
              Select Option
            </option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </Form>
        <Button
          className="text-center"
          onClick={() => {
            this.updateStatus();
          }}
        >
          Update
        </Button>
      </>
    );
  }
}

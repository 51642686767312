import React, { Component } from "react";

import { API_URL } from "variables/general";
import Button from "react-bootstrap/esm/Button";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import CardTitle from "reactstrap/lib/CardTitle";
import Col from "reactstrap/lib/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Input from "reactstrap/lib/Input";
import { Link } from "react-router-dom";
import PreLoader from "assets/img/preloader.gif";
import Row from "reactstrap/lib/Row";
import Select from "react-select";
import TicketList from "./TicketList";

export default class AddTicket extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.getTicketSubject = this.getTicketSubject.bind(this);
    this.getIssue = this.getIssue.bind(this);
    this.getStationID = this.getStationID.bind(this);
  }
  componentDidMount() {
    this.handleStationList();
    this.handleMembership();
  }

  handleMembership = () => {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_URL + "/my/membership", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultDataMembership: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  handleStationList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",

      headers: myHeaders,
      body: JSON.stringify({}),
      redirect: "follow",
    };

    // fetch category details
    fetch(API_URL + "/stationlist", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  createTicket = () => {
    let issue = this.state.issue;
    let subject = this.state.subject;
    let StationID = this.state.StationID;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      // redirect: "follow",
      body: JSON.stringify({
        issue: issue,
        subject: subject,
        StationID: StationID,
      }),
    };
    fetch(API_URL + "/createticket", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let success = JSON.parse(result);
        console.log(result);
        if (success.status) {
          alert("Entry Added Successfully");
          window.location.reload();
        } else alert("Error, Please try again later");
        this.setState({});
      })
      .catch((error) => console.log("error", error));
  };

  getStationID = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ StationID: val });
  };
  getIssue = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ issue: val });
  };
  getTicketSubject = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ subject: val });
  };

  render() {
    let membershipData = this.state.resultDataMembership;
    if (membershipData) {
      var membershipItem = JSON.parse(membershipData).data;
      if (membershipItem.dashboard.isActive) {
        let apiData = this.state.resultData;
        if (apiData) {
          var dataItem = JSON.parse(apiData).data;
          return (
            <div className="content">
              <Row>
                <Col md="6" xs="12">
                  <Card>
                    <CardHeader>Create Ticket</CardHeader>
                    <CardBody>
                      <Col>
                        <CardTitle>
                          <select onChange={this.getStationID}>
                            <option>Select Station</option>
                            {dataItem.stationList.map((val) => (
                              <option value={val.stationId}>
                                {val.stationName}
                              </option>
                            ))}
                          </select>
                        </CardTitle>
                        <Card>
                          <Input
                            placeholder="Enter Ticket Subject"
                            type="text"
                            onChange={this.getTicketSubject}
                          />
                        </Card>
                        <Card>
                          <Input
                            placeholder="Enter Relevant Issue"
                            type="text"
                            onChange={this.getIssue}
                          />
                        </Card>
                        <Button onClick={this.createTicket}>Submit</Button>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="12" lg="12" sm="12" xl="12">
                  <TicketList />
                </Col>
              </Row>
            </div>
          );
        } else {
          return (
            <div
              className="wrapper"
              style={{
                backgroundImage: `url(${PreLoader})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                height: "100%",
              }}
            ></div>
          );
        }
      } else {
        return (
          <div className="content">
            <h4>
              Your Dashboard membership has expired, please make payment to
              continue the services.
            </h4>
            <Link to="/admin/membership"> Click here to Continue</Link>
          </div>
        );
      }
    } else {
      return (
        <div
          className="wrapper"
          style={{
            backgroundImage: `url(${PreLoader})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
          }}
        ></div>
      );
    }
  }
}

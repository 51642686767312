import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { Line, Pie } from "react-chartjs-2";
import {
  Link,
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import React, { Component, useState } from "react";

export default class Databox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      animate: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleButtons = (val) => {
    this.setState({ value: val });
    if (val === 1)
      this.setState({
        result: this.state.today,
      });
    // else if (val == 2)
    //     this.setState({
    //         result: this.state.weekly
    //     });
    // else if (val == 3)
    //     this.setState({
    //         result: this.state.monthly
    //     });
    // else if (val == 4)
    //     this.setState({
    //         result: this.state.yearly
    //     });
    // else if (val == 5)
    //     this.setState({
    //         result: this.state.lifetime
    //     });
  };

  handleClick(e) {
    // modify the state, this will automatically recall render() below.
    this.setState((prevState) => {
      return { animate: !prevState.animate };
    });
  }

  render() {
    const dataItem = this.props;

    // let animationClasses = (this.state.animate ? 'active': '');
    const radios = [
      // { name: 'Today', value: 1 },
      // { name: 'Weekly', value: 2 },
      // { name: 'Monthly', value: 3 },
      // { name: 'Yearly', value: 4 },
      { name: "Lifetime", value: 1 },
    ];

    if (window.localStorage.getItem("token") == null)
      return <Redirect to="/login" />;
    else {
      if (dataItem) {
        var GraphDataWeek = {};
        var options = {};
        var labelsWeek = [];
        for (var i = 0; i < dataItem.weekly.length; i++) {
          labelsWeek[i] = dataItem.weekly[i].weekValue;
        }
        //labels = { "labels": labels }

        var datasetsWeek = [];
        var yAxisData = [];
        for (var i = 0; i < dataItem.weekly.length; i++) {
          yAxisData[i] = dataItem.weekly[i].weekAvg;
        }
        datasetsWeek[0] = {
          data: yAxisData,
          fill: false,
          borderColor: "#fbc658",
          backgroundColor: "transparent",
          pointBorderColor: "#fbc658",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
        };
        GraphDataWeek.labels = labelsWeek;
        GraphDataWeek.datasets = datasetsWeek;
        options = {
          legend: {
            display: false,
            position: "top",
          },
        };

        //monthly
        var GraphDataMonth = {};
        var labelsMonth = [];
        for (var i = 0; i < dataItem.monthly.length; i++) {
         if(dataItem.monthly[i].monValue===1)
         labelsMonth[i]="Jan";
         if(dataItem.monthly[i].monValue===2)
         labelsMonth[i]="Feb";
         if(dataItem.monthly[i].monValue===3)
         labelsMonth[i]="Mar";
         if(dataItem.monthly[i].monValue===4)
         labelsMonth[i]="Apr";
         if(dataItem.monthly[i].monValue===5)
         labelsMonth[i]="May";
         if(dataItem.monthly[i].monValue===6)
         labelsMonth[i]="Jun";
         if(dataItem.monthly[i].monValue===7)
         labelsMonth[i]="Jul";
         if(dataItem.monthly[i].monValue===8)
         labelsMonth[i]="Aug";
         if(dataItem.monthly[i].monValue===9)
         labelsMonth[i]="Sep";
         if(dataItem.monthly[i].monValue===10)
         labelsMonth[i]="Oct";
         if(dataItem.monthly[i].monValue===11)
         labelsMonth[i]="Nov";
         if(dataItem.monthly[i].monValue===12)
         labelsMonth[i]="Dec";
        }

        var datasetsMonth = [];
        var yAxisData = [];
        for (var i = 0; i < dataItem.monthly.length; i++) {
          yAxisData[i] = dataItem.monthly[i].monAvg;
        }
        datasetsMonth[0] = {
          data: yAxisData,
          fill: false,
          borderColor: "#fbc658",
          backgroundColor: "transparent",
          pointBorderColor: "#fbc658",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
        };
        GraphDataMonth.labels = labelsMonth;
        GraphDataMonth.datasets = datasetsMonth;
        return (
          <>
            <div className="content">
              <Row>
                <Col md="12">
                  <Row>
                    <Col md="6" xs="12" sm="12">
                      <Card className="card-chart">
                        <CardHeader>
                          <CardTitle tag="h5">Average Weekly Revenue</CardTitle>
                          <Line data={GraphDataWeek} options={options} />
                        </CardHeader>
                      </Card>
                    </Col>
                    <Col md="6" xs="12" sm="12">
                      <Card className="card-chart">
                        <CardHeader>
                          <CardTitle tag="h5">
                            Average Monthly Revenue
                          </CardTitle>
                          <Line data={GraphDataMonth} options={options} />
                        </CardHeader>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </>
        );
      } else {
        return (
          <div>
            <h2>Loading...</h2>
          </div>
        );
      }
    }
  }
}

import { Redirect, Route, Switch } from "react-router-dom";

import { API_URL } from "variables/general";
import Footer from "components/Footer/Footer.js";
import Header from "components/Navbars/Header.js";
import LocationGroup from "components/LocationGroup/LocationGroup";
import Membership from "components/Membership/Membership";
import PayParams from "components/Payment/PayParams";
import PerfectScrollbar from "perfect-scrollbar";
import React from "react";
import Report from "components/Charger/Report";
import Sidebar from "components/Sidebar/Sidebar.js";
import Stations from "components/Station/Stations";
import routes from "routes.js";

// javascript plugin used to create scrollbars on windows

/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "",
      activeColor: "success",
    };
    this.mainPanel = React.createRef();
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  render() {
    if (window.localStorage.getItem("token") == null)
      window.location.href = "/login";
    else
      return (
        <>
          <div className="wrapper">
            <Sidebar
              {...this.props}
              routes={routes}
              bgColor={this.state.backgroundColor}
              activeColor={this.state.activeColor}
            />

            <div className="main-panel" ref={this.mainPanel}>
              <Header {...this.props} />

              <Switch>
                {routes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.layout + prop.path}
                      component={prop.component}
                      key={key}
                    />
                  );
                })}
                <Route path="/admin/payparams" component={PayParams} />
                <Route path="/admin/location" component={LocationGroup} />
                <Route path="/admin/stations" component={Stations} />
                <Route path="/admin/report" component={Report} />
              </Switch>
            </div>
            {/* <Footer fluid /> */}
          </div>
        </>
      );
  }
}

export default Dashboard;

import { Button, Form } from "react-bootstrap";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";

import { API_URL } from "variables/general";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import PreLoader from "assets/img/preloader.gif";
import React from "react";
import StationDetails from "components/Station/StationDetails";

export default class Stations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: 0,
    };
  }

  componentDidMount() {
    this.handleStationList();
    this.handleMembership();
  }

  handleMembership = () => {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_URL + "/my/membership", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultDataMembership: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  handleStationList = () => {
    let groupId = this.props.history.location.state.groupId;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,

      body: JSON.stringify({
        groupId: groupId,
      }),
    };

    // fetch category details
    fetch(API_URL + "/my/getgroup", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  editStation = () => {
    let stationId = this.state.stationId;
    let newStationName = this.state.newStationName;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        stationId: stationId,
        stationName: newStationName,
      }),
      redirect: "follow",
    };
    fetch(API_URL + "/my/editstation", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let status = JSON.parse(result).status;
        if (status) {
          alert("Details have been updated");
          window.location.reload();
        } else {
          alert("Something Went Wrong");
          window.location.reload();
        }
        this.setState({});
      })
      .catch((error) => console.log("error", error));
  };

  newStationName = (e) => {
    let val = e.target.value;
    this.setState({
      newStationName: val,
    });
  };
  getStationId = (e) => {
    this.setState({
      stationId: e.stationId,
    });
  };
  station_selection = (i) => {
    this.setState({ flag: 1, selected_station: i });
  };
  getStatus = (event) => {
    event.preventDefault();
    let val = event.target.value;
    this.setState({ status: val });
  };
  updateStatus = () => {
    let status = this.state.status;
    let stationId = this.state.stationId;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      // redirect: "follow",
      body: JSON.stringify({
        stationId: stationId,
        privacy: parseInt(status),
      }),
    };
    fetch(API_URL + "/my/editstation", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let userDetail = JSON.parse(result);
        console.log(result);
        if (userDetail.status) {
          alert("Status Updated Successfully");
          window.location.reload();
        } else alert("Error, Please try again later");
        this.setState({});
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    const styler = {
      public: {
        float: "left",
        borderRadius: "10px",
        padding: "5px 15px",
        fontWeight: "bold",
        color: "#ffd",
        backgroundColor: "#28a7ff",
        borderWidth: 0,
      },
      private: {
        float: "left",
        borderRadius: "10px",
        padding: "5px 15px",
        fontWeight: "bold",
        color: "#ffd",
        backgroundColor: "#ff131ad4",
        borderWidth: 0,
      },
      edit: {
        color: "#17a2b8",
        backgroundColor: "white",
        borderWidth: 0,
        float: "right",
      },
    };
    let membershipData = this.state.resultDataMembership;
    let station = this.state.resultData;
    if (membershipData) {
      var membershipItem = JSON.parse(membershipData).data;
      if (membershipItem.dashboard.isActive) {
        if (this.state.flag === 1) {
          return (
            <div className="content">
              <StationDetails
                selected_station={this.state.selected_station}
                groupId={this.props.history.location.state.groupId}
              />
            </div>
          );
        } else {
          if (station) {
            const dataItem = JSON.parse(station).data;
            if (dataItem.count > 0) {
              return (
                <>
                  <div className="content">
                    <Row xs={1} md={3} lg={3} xl={3} className="g-4">
                      {dataItem.station.map((i, index) => (
                        <Col>
                          <Card>
                            <div className="icon-big text-center icon-warning">
                              <Row>
                                <Col>
                                  <button
                                    id="PopoverPrivacy"
                                    onClick={() => this.getStationId(i)}
                                    style={
                                      i.privacy === 1
                                        ? styler.private
                                        : styler.public
                                    }
                                  >
                                    {i.privacy === 1 ? "Private" : "Public"}
                                  </button>
                                  <UncontrolledPopover
                                    trigger="legacy"
                                    placement="bottom"
                                    target="PopoverPrivacy"
                                  >
                                    <Form>
                                      <select
                                        onChange={this.getStatus.bind(this)}
                                      >
                                        <option
                                          value=""
                                          selected
                                          disabled
                                          hidden
                                        >
                                          Select Option
                                        </option>
                                        <option value="1">Private</option>
                                        <option value="0">Public</option>
                                      </select>
                                    </Form>
                                    <Button
                                      className="text-center"
                                      onClick={() => {
                                        this.updateStatus();
                                      }}
                                    >
                                      Set
                                    </Button>
                                  </UncontrolledPopover>

                                  <UncontrolledPopover
                                    trigger="legacy"
                                    placement="bottom"
                                    target="PopoverLegacy"
                                  >
                                    <PopoverHeader>
                                      Edit Station Name
                                    </PopoverHeader>
                                    <PopoverBody>
                                      <label>Enter New Name: </label>
                                      <input
                                        onChange={this.newStationName}
                                        placeholder="Station Name Here..."
                                      ></input>
                                      <br />
                                      <Button onClick={this.editStation}>
                                        Update
                                      </Button>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </Col>
                              </Row>
                              <div>
                                {i.online ? (
                                  <>
                                    <Row>
                                      <Col>
                                        <Card.Img
                                          variant="left"
                                          src={require("assets/img/station_icongreen.png")}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col style={{ color: "green" }}>
                                        <h6>Online</h6>
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  <>
                                    <Row>
                                      <Col>
                                        <Card.Img
                                          variant="left"
                                          src={require("assets/img/station_iconred.png")}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col style={{ color: "red" }}>
                                        <h6>Offline</h6>
                                      </Col>
                                    </Row>
                                  </>
                                )}

                                <Card.Body>
                                  {" "}
                                  <button
                                    id="PopoverLegacy"
                                    style={styler.edit}
                                    onClick={() => this.getStationId(i)}
                                  >
                                    <i class="fas fa-pen"></i>
                                  </button>
                                  <div>
                                    <Card.Title
                                      style={{
                                        textAlign: "center",
                                        fontStyle: "bolder",
                                        fontFamily: "helvetica",
                                        color: "#800020",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => this.station_selection(i)}
                                    >
                                      {i.stationName}{" "}
                                    </Card.Title>{" "}
                                    <Card.Text>{i.stationId}</Card.Text>
                                    <Card.Text style={{ textAlign: "center" }}>
                                      {i.location.address}
                                    </Card.Text>
                                  </div>
                                </Card.Body>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </>
              );
            } else {
              return <div className="content">No Station Found</div>;
            }
          } else {
            return (
              <div
                className="wrapper"
                style={{
                  backgroundImage: `url(${PreLoader})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  height: "100%",
                }}
              ></div>
            );
          }
        }
      } else {
        return (
          <div className="content">
            <h4>
              Your Dashboard membership has expired, please make payment to
              continue the services.
            </h4>
            <Link to="/admin/membership"> Click here to Continue</Link>
          </div>
        );
      }
    } else {
      return (
        <div
          className="wrapper"
          style={{
            backgroundImage: `url(${PreLoader})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
          }}
        ></div>
      );
    }
  }
}

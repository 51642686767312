import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { Line, Pie } from "react-chartjs-2";
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from "variables/charts.js";

import { API_URL } from "variables/general";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/esm/Button";
import ChargerDetails from "./ChargerDetails";
import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import PreLoader from "assets/img/preloader.gif";
import { Progress } from "reactstrap";
import React from "react";
import Stations from "../Station/Stations";
import type1 from "assets/img/type1.png";
import type2 from "assets/img/type2.png";

// import chademo from "assets/img/chademo.png";

export default class Chargers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      pcolor: "",
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.handleChargerList();
  }

  handleChargerList = () => {
    let station_id = this.props.stationId;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",

      headers: myHeaders,
      body: JSON.stringify({
        station_id: station_id,
      }),
      redirect: "follow",
    };

    // fetch category details
    fetch(API_URL + "/chargerlist", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  handleClick(i) {
    this.setState({
      clicked: true,
      chargerId: i,
    });
  }

  render() {
    let pcolor = "success";
    let apiData = this.state.resultData;
    // const lowerVoltage = 69.34;
    // const higherVoltage = 88;
    // let Voltage = [];
    // let voltage = [];
    // if (this.props.dataItem.stationDetails.evse[0].meterValue)
    //   for (
    //     let i = 0;
    //     i < this.props.dataItem.stationDetails.evse[0].meterValue[0].length;
    //     i++
    //   ) {
    //     voltage[i] =
    //       this.props.dataItem.stationDetails.evse[0].meterValue[0][
    //         i
    //       ].sampledValue[0].value;
    //     // Voltage[i] = voltage[i] / 3;
    //   }
    // else {
    //   voltage = ["- - "];
    // }

    // let voltageColor = "warning";

    if (apiData) {
      var dataItem = JSON.parse(apiData).data;
      return (
        <>
          {this.state.clicked ? (
            <ChargerDetails
              chargerId={this.state.chargerId}
              stationId={this.props.stationId}
              // meterValue={this.props.meterValue}
            />
          ) : (
            <>
              <CardHeader>
                <CardTitle tag="h4">Chargers in this station</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Charger ID</th>
                      <th className="text-center">Connector Type</th>
                      <th className="text-center">Power</th>
                      {/* <th className="text-center">Status</th> */}

                      {/* <th className="text-center">Voltage</th> */}
                      <th className="text-center">Total Capacity</th>
                    </tr>
                  </thead>
                  <>
                    {dataItem.chargerList.map((val) => (
                      <tr
                        onClick={() => this.handleClick(val.id)}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#c0d8fa",
                        }}
                      >
                        {(() => {
                          if (val.status === "Operative") {
                            pcolor = "success";
                          } else if (val.status === "Faulted")
                            pcolor = "danger";
                          else if (val.status === "Not Operative")
                            pcolor = "warning";
                          else pcolor = "info";
                        })()}
                        <td className="text-center">
                          <a>{val.id}</a>
                        </td>
                        <td className="text-center">
                          {val.connector.map((item) => (
                            <>
                              <Row>
                                <Col md="6" sm="12" xs="12">
                                  <img
                                    style={{ width: "25px" }}
                                    src={(() => {
                                      switch (item.typeId) {
                                        case 1:
                                          return type1;
                                        case 2:
                                          return type2;
                                        // case 3:
                                        //   return chademo;
                                        // default:
                                        //   return chademo;
                                      }
                                    })()}
                                  />
                                </Col>
                                <Col md="6" sm="12" xs="12">
                                  {item.connectorName}
                                </Col>
                              </Row>
                            </>
                          ))}
                        </td>
                        <td className="text-center">
                          {val.connector.map((i) => (
                            <>
                              {i.maxPower} kW
                              <br />
                            </>
                          ))}
                        </td>
                        {/* <td className="text-center text-success" style={{fontWeight:'700'}}>{val.status}</td> */}
                        {/* <td className="text-center">
                          <Badge pill variant={`${pcolor}`}>
                            {val.status}
                          </Badge>
                        </td> */}

                        {/* <td> */}
                        {/* {voltage.map((i) => ( */}
                        {/* <> */}
                        {/* {(() => {
                                if (i >= lowerVoltage && i <= higherVoltage) {
                                  voltageColor = "success";
                                } else if (i < lowerVoltage)
                                  voltageColor = "danger";
                                else if (i > higherVoltage)
                                  voltageColor = "danger";
                                else voltageColor = "warning";
                              })()}
                              <Progress
                                value={i}
                                color={voltageColor}
                                style={{ margin: "5px" }}
                              >
                                {i * 3} V
                              </Progress> */}
                        {/* {i} V
                              <br />
                            </>
                          ))}
                        </td> */}
                        <td className="text-center">{val.totalCapacity} kW</td>
                      </tr>
                    ))}
                  </>
                </Table>
              </CardBody>
            </>
          )}
        </>
      );
    } else {
      return (
        <div
          className="wrapper"
          style={{
            backgroundImage: `url(${PreLoader})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
          }}
        ></div>
      );
    }
  }
}

import PayStatus from './PayStatus';
import React from 'react'
import {useLocation} from "react-router-dom";

const PayParams = () => {
    

const search = useLocation().search;
const orderId = new URLSearchParams(search).get('ORDER_ID');
    return (
        <div className="content">
            <PayStatus orderId={orderId} />
        </div>
    )
}

export default PayParams

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledAlert,
} from "reactstrap";

import { API_URL } from "variables/general";
import {Link} from "react-router-dom";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import PreLoader from "assets/img/preloader.gif";
/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

class Notifications extends React.Component {
  state = {
    visible: true,
  };

  componentDidMount() {
    this.handleNotification();
    this.handleMembership();
  }

  handleMembership = () => {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_URL + "/my/membership", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultDataMembership: result,
        });
      })
      .catch((error) => console.log("error", error));
  }; 
  handleNotification = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",

      headers: myHeaders,
      body: JSON.stringify({}),
      redirect: "follow",
    };

    // fetch category details
    fetch(API_URL + "/notification", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })

      .catch((error) => console.log("error", error));
  };
  render() {
    let apiData = this.state.resultData;
    let membershipData = this.state.resultDataMembership;
    if (membershipData) {
      var membershipItem = JSON.parse(membershipData).data;
      if (membershipItem.dashboard.isActive) { if (apiData) {
        var dataItem = JSON.parse(apiData).data;
        if (dataItem.notification != 0) {
          return (
            <>
              <div className="content">
                <Row>
                  <Col md="12">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                            <Card className="card-plain">
                              <CardBody>
                                {dataItem.notification.map((val) => (
                                  <Alert
                                    className="alert-with-icon"
                                    color="info"
                                    fade={false}
                                  >
                                    <CardHeader>
                                      <CardTitle tag="h5">{val.title}</CardTitle>
                                    </CardHeader>
                                    <span
                                      data-notify="icon"
                                      className="nc-icon nc-bell-55"
                                    />
                                    <span data-notify="message">
                                      {val.message}
                                    </span>
                                    <br />
                                    Time : {val.createdAt.slice(11, 19)}
                                    &ensp;
                                    <span>
                                      Date : {val.createdAt.slice(0, 10)}
                                    </span>
                                  </Alert>
                                ))}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
          );
        } else {
          return <div className="content"> No Notifications Received Yet</div>;
        }
      } else {
        return (
          <div className="wrapper" style={{backgroundImage: `url(${PreLoader})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: "100%"}}>
            
          </div>
        );
      }}
      else{return <div className="content">
      <h4>Your Dashboard membership has expired, please make payment to continue the services.</h4>
      <Link to="/admin/membership"> Click here to Continue</Link>
    </div>}
    }
    else{ return(
      <div
            className="wrapper"
            style={{
              backgroundImage: `url(${PreLoader})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: "100%",
            }}
          ></div>
    )}

    
   
  }
}

export default Notifications;

import { Button, Card, CardGroup, Col, Row } from "react-bootstrap";
import { Link, Route } from "react-router-dom";

import { API_URL } from "variables/general";
import Container from "reactstrap/lib/Container";
import { Doughnut } from "react-chartjs-2";
import PayParams from "components/Payment/PayParams";
import { Payment } from "components/Payment/Payment";
import PreLoader from "assets/img/preloader.gif";
import React from "react";
import cardbg1 from "assets/img/cardbg1.jpg";
import cardbg2 from "assets/img/cardbg2.jpg";

export default class Membership extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.handleMembership();
  }

  handleMembership = () => {
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_URL + "/my/membership", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  makePayment = (amount) => {
    return fetch(API_URL + "/my/getchecksum", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      body: JSON.stringify({
        planId: amount,
        // "1.00",
      }),
    })
      .then((response) => response.json())

      .then((res) => {
        const script = document.createElement("script");
        script.src =
          "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/" +
          res.data.paytmParams.mid +
          ".js";
        script.onload = function () {
          var config = {
            root: "",
            flow: "DEFAULT",
            data: {
              orderId: res.data.paytmParams.orderId /* update order id */,
              token: res.data.txnToken /* update token value */,
              tokenType: "TXN_TOKEN",
              amount: res.data.paytmParams.txnAmount.value /* update amount */,
            },
            handler: {
              notifyMerchant: function (eventName, data) {
                console.log("notifyMerchant handler function called");
                console.log("eventName => ", eventName);
                console.log("data => ", data);
              },
            },
          };

          if (window.Paytm && window.Paytm.CheckoutJS) {
            window.Paytm.CheckoutJS.onLoad(
              function excecuteAfterCompleteLoad() {
                // initialze configuration using init method
                window.Paytm.CheckoutJS.init(config)
                  .then(function onSuccess() {
                    // after successfully updating configuration, invoke JS Checkout
                    window.Paytm.CheckoutJS.invoke();
                  })
                  .catch(function onError(error) {
                    console.log("error => ", error);
                  });
              }
            );
          }
        };
        document.body.appendChild(script);
      })

      .catch((err) => console.log(err));
  };

  render() {
    let apiData = this.state.resultData;
    const BlankDonought = {
      data: (canvas) => {
        return {
          labels: [],
          datasets: [
            {
              label: "Make Payment",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ["#d8c7c7"],
              borderWidth: 0,

              data: [100, 0],
            },
          ],
        };
      },
      options: {
        legend: {
          display: false,
        },

        pieceLabel: {
          render: "percentage",
          fontColor: ["white"],
          precision: 2,
        },

        tooltips: {
          enabled: false,
        },

        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: "rgba(255,255,255,0.05)",
              },
            },
          ],

          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(255,255,255,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    };
    const DashboardDonought = {
      data: (canvas) => {
        return {
          labels: [1, 2, 3],
          datasets: [
            {
              label: "Dashboard",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ["#165888", "#d8c7c7"],
              borderWidth: 0,

              data: [
                apiData.data.dashboard.remainingValidity,
                apiData.data.dashboard.dashboardPack.validity -
                apiData.data.dashboard.remainingValidity,
                0,
                0,
              ],
            },
          ],
        };
      },
      options: {
        legend: {
          display: false,
        },

        pieceLabel: {
          render: "percentage",
          fontColor: ["white"],
          precision: 2,
        },

        tooltips: {
          enabled: false,
        },

        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: "rgba(255,255,255,0.05)",
              },
            },
          ],

          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(255,255,255,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    };

    const CarrierDonought = {
      data: (canvas) => {
        return {
          labels: [1, 2, 3],
          datasets: [
            {
              label: "Dashboard",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ["#e43b52", "#d8c7c7"],
              borderWidth: 0,

              data: [
                apiData.data.carrier.remainingValidity,
                apiData.data.carrier.carrierPack.validity -
                apiData.data.carrier.remainingValidity,

                0,
                0,
              ],
            },
          ],
        };
      },
      options: {
        legend: {
          display: false,
        },

        pieceLabel: {
          render: "percentage",
          fontColor: ["white"],
          precision: 2,
        },

        tooltips: {
          enabled: false,
        },

        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: "rgba(255,255,255,0.05)",
              },
            },
          ],

          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(255,255,255,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    };

    if (apiData) {
      let dashboardCard;
      if (apiData.data.dashboard.status === "active") {
        dashboardCard = (
          <Card>
            <h4 style={{ textAlign: "center" }}>Dashboard</h4>
            <Doughnut
              data={DashboardDonought.data}
              options={DashboardDonought.options}
            ></Doughnut>
            <Container>
              Plan Type:{" "}
              <i style={{ color: "#5fca8e", fontSize: "20px" }}>
                {apiData.data.planName}
              </i>
              <br />
              Remaining Validity:{" "}
              <i style={{ color: "#165888", fontSize: "24px" }}>
                {apiData.data.dashboard.remainingValidity} days{" "}
              </i>
            </Container>
          </Card>
        );
      } else if (apiData.data.dashboard.status === "expired") {
        dashboardCard = (
          <Card>
            <h4 style={{ textAlign: "center" }}>Dashboard</h4>
            <Doughnut
              data={BlankDonought.data}
              options={BlankDonought.options}
            ></Doughnut>
            <Container>
              <h6 style={{ textAlign: "center", margin: "17px" }}>
                Your dashboard plan has expired! Please make payment to enjoy
                uninterrupted services.
              </h6>
            </Container>
          </Card>
        );
      }

      let carrierCard;
      if (apiData.data.carrier.status === "active") {
        carrierCard = (
          <Card>
            <h4 style={{ textAlign: "center" }}>GSM Carrier</h4>
            <Doughnut
              data={CarrierDonought.data}
              options={CarrierDonought.options}
            ></Doughnut>
            <Container>
              Plan Type:{" "}
              <i style={{ color: "#5fca8e", fontSize: "20px" }}>
                {apiData.data.planName}
              </i>
              <br />
              Remaining Validity:{" "}
              <i style={{ color: "#165888", fontSize: "24px" }}>
                {apiData.data.carrier.remainingValidity} days{" "}
              </i>
            </Container>
          </Card>
        );
      } else if (apiData.data.carrier.status === "expired") {
        carrierCard = (
          <Card>
            <h4 style={{ textAlign: "center" }}>GSM Carrier</h4>
            <Doughnut
              data={BlankDonought.data}
              options={BlankDonought.options}
            ></Doughnut>
            <Container>
              <h6 style={{ textAlign: "center", margin: "17px" }}>
                Your carrier plan has expired! Please make payment to enjoy
                uninterrupted services.
              </h6>
            </Container>
          </Card>
        );
      }
      let notActiveDashboard = (
        <Card>
          <h4 style={{ textAlign: "center" }}>Dashboard</h4>
          <Container>
            <h6 style={{ textAlign: "center", margin: "130px 0" }}>
              Your Dashboard services are blocked due to non payment, please
              make payment to continue the services.
            </h6>
          </Container>
        </Card>
      );
      let notActiveCarrier = (
        <Card>
          <h4 style={{ textAlign: "center" }}>GSM Carrier</h4>
          <Container>
            <h6 style={{ textAlign: "center", margin: "130px 0" }}>
              Your GSM Carrier services are blocked due to non payment, please
              make payment to continue the services.
            </h6>
          </Container>
        </Card>
      );
      return (
        <>
          <div className="content">
            <Container>
              {/* <r
                style={{
                  fontSize: "24px",
                  textTransform: "uppercase",
                  fontWeight: "bolder",
                }}
              >
                {" "}
                Active Plan Details
              </r> */}

              <Row>
                <Col md="6" xs="12" sm="12">
                  {apiData.data.carrier.isActive
                    ? dashboardCard
                    : notActiveDashboard}
                </Col>
                <Col md="6" xs="12" sm="12">
                  {apiData.data.carrier.isActive
                    ? carrierCard
                    : notActiveCarrier}
                </Col>
              </Row>
              {apiData.data.dashboard.showPaymentOption ? (
                <Card
                  style={{
                    background:
                      "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",
                    color: "#3c2a2a",
                  }}
                >
                  <Container>
                    <h3 style={{ textAlign: "center" }}>Dashboard Payment</h3>
                    <Row style={{ margin: "15px 0 0 0" }}>
                      {/* <Col md="6" xs="12" sm="12"> */}
                      <Col>
                        <h6 style={{ textAlign: "center" }}>Due Date: </h6>
                        <h3 style={{ textAlign: "center" }}>
                          {" "}
                          {apiData.data.dashboard.dueDate.slice(8, 10)}-
                          {apiData.data.dashboard.dueDate.slice(5, 7)}-
                          {apiData.data.dashboard.dueDate.slice(0, 4)}
                        </h3>
                      </Col>
                      {/* <Col md="6" xs="12" sm="12">
                        <h6 style={{ textAlign: "center" }}>Amount: </h6>
                        <h3 style={{ textAlign: "center" }}>
                          ₹ {apiData.data.dashboard.total}
                        </h3>
                      </Col> */}
                    </Row>
                    <Row>
                      {/* <Col md="8" style={{ textAlign: "center" }}> */}
                      <Col >
                        {/* <Button
                          style={{
                            width: "22.5em",
                            height: "3.5em",
                            backgroundColor: "#173738",
                          }}
                          onClick={(e) =>
                            this.makePayment(apiData.data.dashboard.planId)
                          }
                          disabled
                        >
                          Make Payment
                        </Button> */}
                        <span>To Make the payment Please contact us at info@aeidth.com.</span>
                      </Col>
                      {/* <Col md="4">
                        <p>
                          <br />₹ {apiData.data.dashboard.dashboardPack.price} x{" "}
                          {apiData.data.noOfStation} = ₹{" "}
                          {apiData.data.dashboard.total}
                          <br />
                          Validity:{" "}
                          {apiData.data.dashboard.dashboardPack.validity} days
                        </p>
                      </Col> */}
                    </Row>
                  </Container>
                </Card>
              ) : null}
              {apiData.data.carrier.showPaymentOption ? (
                <Card
                  style={{
                    background:
                      "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",
                    color: "#3c2a2a",
                  }}
                >
                  <Container>
                    <h3 style={{ textAlign: "center" }}>GSM Carrier Payment</h3>
                    <Row style={{ margin: "15px 0 0 0" }}>
                      <Col>
                        <h6 style={{ textAlign: "center" }}>Due Date: </h6>
                        <h3 style={{ textAlign: "center" }}>
                          {" "}
                          {apiData.data.carrier.dueDate.slice(8, 10)}-
                          {apiData.data.carrier.dueDate.slice(5, 7)}-
                          {apiData.data.carrier.dueDate.slice(0, 4)}
                        </h3>
                      </Col>
                      {/* <Col md="6" xs="12" sm="12">
                        <h6 style={{ textAlign: "center" }}>Amount: </h6>
                        <h3 style={{ textAlign: "center" }}>
                          ₹ {apiData.data.carrier.total}
                        </h3>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col>
                        {/* <Button
                          style={{
                            width: "22.5em",
                            height: "3.5em",
                            backgroundColor: "#173738",
                          }}
                          onClick={(e) =>
                            this.makePayment(apiData.data.carrier.planId)
                          }
                          disabled
                        >
                          Make Payment
                        </Button> */}
                        <span>To Make the payment Please contact us at info@aeidth.com.</span>
                      </Col>
                      {/* <Col md="4">
                        <p>
                          <br />₹ {apiData.data.carrier.carrierPack.price} x{" "}
                          {apiData.data.noOfStation} = ₹{" "}
                          {apiData.data.carrier.total}
                          <br />
                          Validity: {
                            apiData.data.carrier.carrierPack.validity
                          }{" "}
                          days
                        </p>
                      </Col> */}
                    </Row>
                  </Container>
                </Card>
              ) : null}
            </Container>
          </div>
        </>
      );
    } else {
      return (
        <div
          className="wrapper"
          style={{
            backgroundImage: `url(${PreLoader})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
          }}
        ></div>
      );
    }
  }
}

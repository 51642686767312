import {
  CardBody,
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Row,
} from "reactstrap";

import { API_URL } from "variables/general";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import Membership from "components/Membership/Membership";
import React from "react";
import { Redirect } from "react-router-dom";
import rajatdp from "assets/img/rajatp.jpg";
import routes from "routes.js";

/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
      // log: false
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.sidebarToggle = React.createRef();
  }

  // handleNotificationCount = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", "Bearer " + window.localStorage.getItem("token"));
  //   var requestOptions = {
  //     method: "POST",

  //     headers: myHeaders,
  //     body: JSON.stringify({

  //     }),
  //     redirect: "follow",
  //   };

  //   // fetch notification count
  //   fetch(API_URL + "/notification", requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => {
  //       this.setState({
  //         notificationData: result,
  //       });
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  handleUserDetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",

      headers: myHeaders,
      body: JSON.stringify({}),
      redirect: "follow",
    };

    // fetch user details
    fetch(API_URL + "/my/userdetails", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          resultData: result,
        });
      })
      .catch((error) => console.log("error", error));
  };

  logOut = () => {
    // this.setState({
    //   log: true,
    // });
    window.localStorage.clear();
    window.location.href = "/login";
  };
  clickNotificationBell = () => {
    window.location.href = "/admin/notifications";
  };
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "dark",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }
  getBrand() {
    let brandName = "Dashboard";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  }
  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  }
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  }
  componentDidMount() {
    this.handleUserDetails();
    // this.handleNotificationCount();
    window.addEventListener("resize", this.updateColor.bind(this));
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }
  }

  render() {
    // let notificationcount = this.state.notificationData;
    let apiData = this.state.resultData;
    if (apiData) {
      // const notificationCount= JSON.parse(notificationcount).data;
      var dataItem = JSON.parse(apiData).data;

      let show;
      if (dataItem.unseenNotificationCount === 0) {
        show = null;
      } else show = dataItem.unseenNotificationCount;
      return (
        // add or remove classes depending if we are on full-screen-maps page or not
        <>
          <Navbar
            color={
              this.props.location.pathname.indexOf("full-screen-maps") !== -1
                ? "dark"
                : this.state.color
            }
            expand="lg"
            className={
              this.props.location.pathname.indexOf("full-screen-maps") !== -1
                ? "navbar-absolute fixed-top"
                : "navbar-absolute fixed-top " +
                  (this.state.color === "transparent"
                    ? "navbar-transparent "
                    : "")
            }
          >
            <Container fluid>
              <div className="navbar-wrapper">
                <div className="navbar-toggle">
                  <button
                    type="button"
                    ref={this.sidebarToggle}
                    className="navbar-toggler"
                    onClick={() => this.openSidebar()}
                  >
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </button>
                </div>
                <NavbarBrand>{this.getBrand()}</NavbarBrand>
              </div>
              <NavbarToggler onClick={this.toggle}>
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
              </NavbarToggler>
              <Collapse
                isOpen={this.state.isOpen}
                navbar
                className="justify-content-end"
              >
                <Nav navbar>
                  <NavbarBrand>
                    {dataItem.userDetail.firstName}{" "}
                    {dataItem.userDetail.lastName}
                  </NavbarBrand>
                  <Link to="/admin/notifications">
                    <Button>
                      <i className="nc-icon nc-bell-55" />
                      <sup style={{ color: "red" }}>{show}</sup>
                    </Button>
                  </Link>

                  <NavItem>
                    <Button onClick={this.logOut}>Log Out</Button>
                  </NavItem>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </>
      );
    } else {
      return (
        <div>
          <h2>Loading...</h2>
        </div>
      );
    }
  }
}

export default Header;

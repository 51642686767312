import React, { Component } from "react";

import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";

class MembershipAlertCarrier extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const styler = {
      main: {
        fontWeight: "bolder",
        fontSize: "17px",
      },

      warning: {
        color: "blue",
      },
    };
    let apiData = this.props;

    if (apiData) {
      if (apiData.isAlert) {
        if (apiData.carrier.remainingValidity >= 30) {
          return (
            <>
              <Alert variant="success">
                Active Carrier Plan:{" "}
                <b style={styler.main}>{apiData.planName}</b>
              </Alert>
              <Alert variant="info">
                Remaining Carrier Validity is :{" "}
                <b style={styler.main}>{apiData.carrier.remainingValidity}</b>
              </Alert>
            </>
          );
        } else if (
          apiData.carrier.remainingValidity >= 5 &&
          apiData.carrier.remainingValidity <= 30
        ) {
          return (
            <>
              {" "}
              <Alert variant="success">
                Active Carrier Plan:{" "}
                <b style={styler.main}>{apiData.planName}</b>
              </Alert>
              <Alert variant="warning">
                <b style={styler.warning}>
                  {" "}
                  Your carrier Services will Expire in{" "}
                  <b style={styler.main}>
                    {apiData.carrier.remainingValidity} days
                  </b>
                  . Please make Payment before Due Date to enjoy uninterrupted
                  services.{" "}
                </b>
              </Alert>
            </>
          );
        } else if (
          apiData.carrier.remainingValidity <= 5 &&
          apiData.carrier.remainingValidity > 0
        ) {
          return (
            <>
              <Alert variant="success">
                Active Carrier Plan:{" "}
                <b style={styler.main}>{apiData.planName}</b>
              </Alert>

              <Alert variant="danger">
                Your Carrier Services are about to Expire in{" "}
                <b style={styler.main}>{apiData.carrier.remainingValidity}</b>{" "}
                days. Please make Payment immediately to enjoy uninterrupted
                services.
              </Alert>
            </>
          );
        } else if (apiData.carrier.remainingValidity === 0) {
          return (
            <>
              <Alert variant="danger">
                Your Carrier Membership has <b style={styler.main}>Expired!</b>
              </Alert>

              <Alert variant="danger">
                Please{" "}
                <Link
                  to="/admin/membership"
                  style={{ color: "#66615B", fontWeight: "bolder" }}
                >
                  {" "}
                  Make Payment Immediately{" "}
                </Link>
                to resume services.
              </Alert>
            </>
          );
        }
      }
    }
  }
}

export default MembershipAlertCarrier;
